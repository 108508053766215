import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Carousel, ProgressBar, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { checkToken } from "../../redux/actions/userActions";

// components
import Topbar from "./components/topbar";
import HowItWorks from "./components/howItWorks";

// images
import rewards from "../../assets/image/rewards.png";
import presentGrey from "../../assets/image/presentGrey.png";
import previousIcon from "../../assets/image/previousIcon.png";
import nextIcon from "../../assets/image/nextIcon.png";

const RewardsRedemption = ({ user, token, checkToken, stamp }) => {

    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate('/login');
        } else if (!user) {
            checkToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Topbar />

            <div className="container">
                <p className="page-title">REWARDS REDEMPTION</p>
                <div className="px-5 pb-3">
                    <img src={rewards} alt="rewards" className="welcome-product-img" />
                    <p className="auth-subtitle">Stand a chance to win exciting rewards from Abbott</p>
                </div>

                <HowItWorks />

                {
                    stamp ? (
                        <Carousel indicators={false} variant="dark" interval={null} className="pt-3" nextIcon={<div className="carousel-icon"><img src={nextIcon} alt="nextIcon" /></div>} prevIcon={<div className="carousel-icon"><img src={previousIcon} alt="previousIcon" /></div>}>

                            <Carousel.Item>
                                <div className="rewards-card">
                                    <div className="container">
                                        <div className="row justify-content-between pt-3">
                                            <p className="rewards-title col">Freestyle Libre Sensors puchased</p>
                                            <p className="rewards-number col-2">4</p>
                                        </div>
                                        <div className="row justify-content-between">
                                            <div className="col pt-2 d-flex">
                                                <div className="col"><ProgressBar now={stamp.sensor % 4 > 0 ? 100 : 0} className="rewards-progess-bar" /></div>
                                                <div className="col ps-1"><ProgressBar now={stamp.sensor % 4 > 1 ? 100 : 0} className="rewards-progess-bar" /></div>
                                                <div className="col ps-1"><ProgressBar now={stamp.sensor % 4 > 2 ? 100 : 0} className="rewards-progess-bar" /></div>
                                                <div className="col ps-1"><ProgressBar now={stamp.sensor % 4 > 3 ? 100 : 0} className="rewards-progess-bar" /></div>
                                            </div>
                                            <div className="col-2"><img src={presentGrey} alt="present" /></div>
                                        </div>
                                        <p className="rewards-subtitle pb-3 pt-2"><b>{stamp.sensor % 4 === 0 ? 4 : stamp.sensor % 4 === 1 ? 3 : stamp.sensor % 4 === 2 ? 2 : stamp.sensor % 4 === 3 ? 1 : stamp.sensor % 4 === 4 ? 0 : 4} more</b> to redeem the 5th FREE!</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="rewards-card">
                                    <div className="container">
                                        <div className="row justify-content-between pt-3">
                                            <p className="rewards-title col">Freestyle Optium Neo Strips 50s puchased</p>
                                            <p className="rewards-number col-2">4</p>
                                        </div>
                                        <div className="row justify-content-between">
                                            <div className="col pt-2 d-flex">
                                                <div className="col"><ProgressBar now={stamp.strip % 4 > 0 ? 100 : 0} className="rewards-progess-bar" /></div>
                                                <div className="col ps-1"><ProgressBar now={stamp.strip % 4 > 1 ? 100 : 0} className="rewards-progess-bar" /></div>
                                                <div className="col ps-1"><ProgressBar now={stamp.strip % 4 > 2 ? 100 : 0} className="rewards-progess-bar" /></div>
                                                <div className="col ps-1"><ProgressBar now={stamp.strip % 4 > 3 ? 100 : 0} className="rewards-progess-bar" /></div>
                                            </div>
                                            <div className="col-2"><img src={presentGrey} alt="present" /></div>
                                        </div>
                                        <p className="rewards-subtitle pb-3 pt-2"><b>{stamp.strip % 4 === 0 ? 4 : stamp.strip % 4 === 1 ? 3 : stamp.strip % 4 === 2 ? 2 : stamp.strip % 4 === 3 ? 1 : stamp.strip % 4 === 4 ? 0 : 4} more</b> to redeem the 5th FREE!</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div className="rewards-card">
                                    <div className="container">
                                        <div className="row justify-content-between pt-3">
                                            <p className="rewards-title col">Freestyle Lite Strips 50s puchased</p>
                                            <p className="rewards-number col-2">4</p>
                                        </div>
                                        <div className="row justify-content-between">
                                            <div className="col pt-2 d-flex">
                                                <div className="col"><ProgressBar now={stamp.liteStrip % 4 > 0 ? 100 : 0} className="rewards-progess-bar" /></div>
                                                <div className="col ps-1"><ProgressBar now={stamp.liteStrip % 4 > 1 ? 100 : 0} className="rewards-progess-bar" /></div>
                                                <div className="col ps-1"><ProgressBar now={stamp.liteStrip % 4 > 2 ? 100 : 0} className="rewards-progess-bar" /></div>
                                                <div className="col ps-1"><ProgressBar now={stamp.liteStrip % 4 > 3 ? 100 : 0} className="rewards-progess-bar" /></div>
                                            </div>
                                            <div className="col-2"><img src={presentGrey} alt="present" /></div>
                                        </div>
                                        <p className="rewards-subtitle pb-3 pt-2"><b>{stamp.liteStrip % 4 === 0 ? 4 : stamp.liteStrip % 4 === 1 ? 3 : stamp.liteStrip % 4 === 2 ? 2 : stamp.liteStrip % 4 === 3 ? 1 : stamp.liteStrip % 4 === 4 ? 0 : 4} more</b> to redeem the 5th FREE!</p>
                                    </div>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                    ) : <Spinner style={{ display: "block", margin: "auto", marginTop: "5px" }} animation="border" variant="primary" />
                }

                <div className="px-5 py-3">
                    <button type="button" className="btn btn-primary form-control orange-btn" onClick={() => navigate('/rewards')}>REWARDS</button>
                    <button type="button" className="btn btn-primary form-control mt-2 submit-btn" onClick={() => navigate('/uploadReceipt')}>UPLOAD RECEIPT</button>
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        token: state.user.token,
        stamp: state.user.stamp
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkToken: () => dispatch(checkToken()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RewardsRedemption);