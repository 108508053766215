import { Link } from 'react-router-dom';
import { adminLogout } from "../../../redux/actions/adminActions";
import { connect } from 'react-redux';

const AdminTopbar = ({ adminLogout }) => {

    return (
        <div>
            <nav className="navbar">
                <Link to="/admin/login" className="btn">
                    <button onClick={adminLogout} className="btn" style={{ color: "white" }}>
                        <i className="fa fa-sign-out"></i>
                    </button>
                </Link>
            </nav>
        </div>
    )

}

const mapDispatchToProps = dispatch => {
    return {
        adminLogout: () => dispatch(adminLogout()),
    };
};

export default connect(null, mapDispatchToProps)(AdminTopbar);