import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ProgressBar, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { checkToken, updateSensorStamp, updateStripStamp, updateLiteStripStamp, updateReward, updateVoucher } from "../../redux/actions/userActions";
import { Spinner } from 'react-bootstrap';
import axios from 'axios';

// components
import Topbar from "./components/topbar";

// images
import presentWhite from "../../assets/image/presentWhite.png";
import sucess from "../../assets/image/success.png";
import presentGrey from "../../assets/image/presentGrey.png";
import sensor from "../../assets/image/sensor.png";
import strips from "../../assets/image/optiumNeo.png";
import liteStrips from "../../assets/image/liteStrips.jpg";
import tng from "../../assets/image/tng.png";

const ClaimRewards = ({ user, token, checkToken, addresses, stamp, updateSensorStamp, updateStripStamp, updateLiteStripStamp, updateReward, updateVoucher }) => {

    const [address, setAddress] = useState("");
    const [show, setShow] = useState(false);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [tngLoading, setTngLoading] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const param = useParams();

    useEffect(() => {
        if (!token) {
            navigate('/login');
        } else if (param.reward !== "tng" && param.reward !== "sensor" && param.reward !== "strip" && param.reward !== "litestrip" && param.reward !== "tngRedeemed") {
            navigate('/homepage');
        } else if (param.reward === "tngRedeemed" && !location.state) {
            navigate('/homepage');
        } else if (!user) {
            checkToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = () => {
        setShow(false);
        navigate('/homepage');
    }

    const submitClaim = () => {
        if (param.reward === "tng") {
            setError("Please click the Redeem Now button to redeem your TouchN'Go E-Wallet Reload Pin");
        } else if (param.reward === "tngRedeemed") {
            setError("Please click the View Redeemed Tng Voucher button to view your TouchN'Go E-Wallet Reload Pin");
        } else if (address) {
            if (param.reward === "sensor") {
                if (stamp.sensor >= 4) {
                    setError("")
                    setLoading(true);

                    axios.post('/api/reward/redeem', { number: user.number, addressId: address, mytype: param.reward })
                        .then(res => {
                            updateReward(res.data.reward);
                            updateSensorStamp(stamp.sensor - 4);
                            setLoading(false);
                            setShow(true);
                        })
                        .catch(err => {
                            setError(err.response.data.error);
                            setLoading(false);
                        });
                } else {
                    setError("Invalid number of purchase to redeem rewards");
                }
            } else if (param.reward === "strip") {
                if (stamp.strip >= 4) {
                    setError("")
                    setLoading(true);

                    axios.post('/api/reward/redeem', { number: user.number, addressId: address, mytype: param.reward })
                        .then(res => {
                            updateReward(res.data.reward);
                            updateStripStamp(stamp.strip - 4);
                            setLoading(false);
                            setShow(true);
                        })
                        .catch(err => {
                            setError(err.response.data.error);
                            setLoading(false);
                        });
                } else {
                    setError("Invalid number of purchase to redeem rewards");
                }
            } else if (param.reward === "litestrip") {
                if (stamp.liteStrip >= 4) {
                    setError("")
                    setLoading(true);

                    axios.post('/api/reward/redeem', { number: user.number, addressId: address, mytype: param.reward })
                        .then(res => {
                            updateReward(res.data.reward);
                            updateLiteStripStamp(stamp.liteStrip - 4);
                            setLoading(false);
                            setShow(true);
                        })
                        .catch(err => {
                            setError(err.response.data.error);
                            setLoading(false);
                        });
                } else {
                    setError("Invalid number of purchase to redeem rewards");
                }
            }
        }
        else {
            setError("Please choose your delivery address");
        }
    }

    const handleAddress = e => {
        setAddress(e.target.id);
    }

    const redeemTng = () => {
        setError("")
        setTngLoading(true);

        axios.post('/api/reward/redeemVouc', { number: user.number })
            .then(res => {
                updateVoucher(res.data.vouchers);
                setTngLoading(false);
                navigate('/tngRedemption', { state: { tngCode: res.data.voucher.code, tngValue: res.data.voucher.value || 80 } });
            })
            .catch(err => {
                setError(err.response.data.error);
                setTngLoading(false);
            });
    }

    const renderReward = () => {
        if (param.reward === "sensor" || param.reward === "tng" || param.reward === "tngRedeemed") {
            return (
                <div className="rewards-page-card" style={{ backgroundColor: stamp.sensor >= 4 ? "#FFD100" : null }}>
                    <div className="container">
                        <div className="row justify-content-between pt-3">
                            <div className="col d-flex">
                                <div className="receipt-image-card"><img src={sensor} alt="sensor" style={{ width: "50px" }} /></div>
                                <p className="rewards-title ps-1">Freestyle Libre Sensors puchased</p>
                            </div>
                            <p className="rewards-number col-2">4</p>
                        </div>
                        <div className="row justify-content-between">
                            <div className="col pt-2 d-flex">
                                <div className="col"><ProgressBar now={stamp.sensor >= 1 ? 100 : 0} className="rewards-progess-bar" /></div>
                                <div className="col ps-1"><ProgressBar now={stamp.sensor >= 2 ? 100 : 0} className="rewards-progess-bar" /></div>
                                <div className="col ps-1"><ProgressBar now={stamp.sensor >= 3 ? 100 : 0} className="rewards-progess-bar" /></div>
                                <div className="col ps-1"><ProgressBar now={stamp.sensor >= 4 ? 100 : 0} className="rewards-progess-bar" /></div>
                            </div>
                            <div className="col-2"><img src={stamp.sensor >= 4 ? presentWhite : presentGrey} alt="present" /></div>
                        </div>
                        <p className="rewards-subtitle pt-2"><b>{stamp.sensor === 0 ? 4 : stamp.sensor === 1 ? 3 : stamp.sensor === 2 ? 2 : stamp.sensor === 3 ? 1 : stamp.sensor >= 4 ? 0 : 4} more</b> to redeem the 5th FREE!</p>
                        <p className="rewards-small-title pb-1"> &#187; View Participating Channels</p>
                    </div>
                </div>
            )
        } else if (param.reward === "strip") {
            return (
                <div className="rewards-page-card" style={{ backgroundColor: stamp.strip >= 4 ? "#FFD100" : null }}>
                    <div className="container">
                        <div className="row justify-content-between pt-3">
                            <div className="col d-flex">
                                <div className="receipt-image-card" style={{ width: "50px" }}><img src={strips} alt="strips" style={{ width: "30px", display: "block", margin: "auto" }} /></div>
                                <p className="rewards-title ps-1">Freestyle Optium Neo Strips 50s puchased</p>
                            </div>
                            <p className="rewards-number col-2">4</p>
                        </div>
                        <div className="row justify-content-between">
                            <div className="col pt-2 d-flex">
                                <div className="col"><ProgressBar now={stamp.strip >= 1 ? 100 : 0} className="rewards-progess-bar" /></div>
                                <div className="col ps-1"><ProgressBar now={stamp.strip >= 2 ? 100 : 0} className="rewards-progess-bar" /></div>
                                <div className="col ps-1"><ProgressBar now={stamp.strip >= 3 ? 100 : 0} className="rewards-progess-bar" /></div>
                                <div className="col ps-1"><ProgressBar now={stamp.strip >= 4 ? 100 : 0} className="rewards-progess-bar" /></div>
                            </div>
                            <div className="col-2"><img src={stamp.strip >= 4 ? presentWhite : presentGrey} alt="present" /></div>
                        </div>
                        <p className="rewards-subtitle pt-2"><b>{stamp.strip === 0 ? 4 : stamp.strip === 1 ? 3 : stamp.strip === 2 ? 2 : stamp.strip === 3 ? 1 : stamp.strip >= 4 ? 0 : 4} more</b> to redeem the 5th FREE!</p>
                        <p className="rewards-small-title pb-1"> &#187; View Participating Channels</p>
                    </div>
                </div>
            )
        } else if (param.reward === "litestrip") {
            return (
                <div className="rewards-page-card" style={{ backgroundColor: stamp.liteStrip >= 4 ? "#FFD100" : null }}>
                    <div className="container">
                        <div className="row justify-content-between pt-3">
                            <div className="col d-flex">
                                <div className="receipt-image-card"><img src={liteStrips} alt="liteStrips" style={{ width: "50px" }} /></div>
                                <p className="rewards-title ps-1">Freestyle Lite Strips 50s puchased</p>
                            </div>
                            <p className="rewards-number col-2">4</p>
                        </div>
                        <div className="row justify-content-between">
                            <div className="col pt-2 d-flex">
                                <div className="col"><ProgressBar now={stamp.liteStrip >= 1 ? 100 : 0} className="rewards-progess-bar" /></div>
                                <div className="col ps-1"><ProgressBar now={stamp.liteStrip >= 2 ? 100 : 0} className="rewards-progess-bar" /></div>
                                <div className="col ps-1"><ProgressBar now={stamp.liteStrip >= 3 ? 100 : 0} className="rewards-progess-bar" /></div>
                                <div className="col ps-1"><ProgressBar now={stamp.liteStrip >= 4 ? 100 : 0} className="rewards-progess-bar" /></div>
                            </div>
                            <div className="col-2"><img src={stamp.liteStrip ? presentWhite : presentGrey} alt="present" /></div>
                        </div>
                        <p className="rewards-subtitle pt-2"><b>{stamp.liteStrip === 0 ? 4 : stamp.liteStrip === 1 ? 3 : stamp.liteStrip === 2 ? 2 : stamp.liteStrip === 3 ? 1 : stamp.liteStrip >= 4 ? 0 : 4} more</b> to redeem the 5th FREE!</p>
                        <p className="rewards-small-title pb-1"> &#187; View Participating Channels</p>
                    </div>
                </div>
            )
        }
    }

    const renderTNG = () => {
        if (param.reward === "tng" || param.reward === "tngRedeemed") {
            return (
                <div className="rewards-page-card mb-3" style={{ backgroundColor: "#FFD100" }}>
                    <div className="container">
                        <div className="row justify-content-between py-3">
                            <div className="col">
                                <p className="tng-title">TouchN'Go E-Wallet Reload Pin</p>
                                <p className="tng-title"><b>RM80</b></p>

                                {
                                    tngLoading ? (
                                        <Spinner style={{ display: "block", margin: "auto", marginTop: "5px" }} animation="border" variant="primary" />
                                    ) : param.reward === "tng" ? (
                                        <button type="button" style={{ fontSize: "13px", width: "130px" }} className="btn btn-primary form-control submit-btn" onClick={() => redeemTng()}>Redeem Now</button>
                                    ) : (
                                        <button type="button" style={{ fontSize: "13px", width: "150px" }} className="btn btn-primary form-control submit-btn" onClick={() => navigate('/tngRedemption', { state: { tngCode: location.state.tngCode, tngValue: location.state.tngValue } })}>View Redeemed Tng Voucher</button>
                                    )
                                }

                            </div>
                            <div className="col-4">
                                <img src={tng} alt="tng" />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div>
            <Topbar />

            <div className="container">
                <p className="page-title">REWARDS</p>

                {
                    renderTNG()
                }

                {
                    renderReward()
                }

                <p className="text-center pt-4">Choose your delivery address</p>

                <div className="px-3">
                    {
                        addresses ? (addresses.map(a => {
                            return (
                                <div className="faq-card mt-3" style={{ borderColor: address === a._id ? "#0d6efd" : null }} key={a._id}>
                                    <div className="px-3 py-3 d-flex">
                                        <div>
                                            <input type="radio" id={a._id} name="address" className="radio-btn" onChange={e => handleAddress(e)} />
                                        </div>
                                        <div className="ps-3">
                                            <p className="address-subtitle"><b>{user.name}</b> (+6{user.number})</p>
                                            <p className="address-subtitle">{a.line1} {a.line2} {a.city} {a.postCode} {a.state} {a.country}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })) : <Spinner style={{ display: "block", margin: "auto", marginTop: "5px" }} animation="border" variant="primary" />
                    }

                    <p className="text-end pt-2" onClick={() => navigate('/addAddress')}><b> + Add New Address</b></p>

                    <div>
                        <p style={{ color: "red", textAlign: "center" }}>{error ? error : null}</p>
                    </div>

                    {
                        loading ? (
                            <div className="pt-5">
                                <Spinner style={{ display: "block", margin: "auto", marginTop: "5px" }} animation="border" variant="primary" />
                            </div>
                        ) : (
                            <div className="px-5 py-3">
                                <button type="button" className="btn btn-primary form-control submit-btn" onClick={() => submitClaim()}>SUBMIT</button>
                            </div>
                        )
                    }

                </div>

            </div>

            <Modal show={show} onHide={handleClose} backdrop="static" centered backdropClassName="modal-back-drop">
                <Modal.Body>
                    <img src={sucess} alt="sucess" className="welcome-product-img" />
                    <p className="welcome-title">DELIVERY REQUEST SENT!</p>
                    <p className="text-center">Your premium gift delivery request has been sent. The free product will be delivered within <b>1 to 2 weeks</b></p>
                    <div className="px-4">
                        <button type="button" className="btn btn-primary form-control mt-3 orange-btn" onClick={() => handleClose()}>OKAY</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        token: state.user.token,
        addresses: state.user.address,
        stamp: state.user.stamp
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkToken: () => dispatch(checkToken()),
        updateSensorStamp: data => dispatch(updateSensorStamp(data)),
        updateStripStamp: data => dispatch(updateStripStamp(data)),
        updateLiteStripStamp: data => dispatch(updateLiteStripStamp(data)),
        updateReward: data => dispatch(updateReward(data)),
        updateVoucher: data => dispatch(updateVoucher(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimRewards);