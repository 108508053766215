import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkToken } from "../../redux/actions/userActions";

// components
import Topbar from "./components/topbar";

// images
import faqArrow from "../../assets/image/faqArrow.png";

const Help = ({ user, token, checkToken }) => {

    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate('/login');
        } else if (!user) {
            checkToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Topbar />

            <div className="container">
                <p className="page-title">HELP</p>
                <div className="px-3 pt-1">
                    <p><b>FREQUENTLY-ASKED-QUESTIONS (FAQ)</b></p>

                    <div className="faq-card" onClick={() => navigate('/help/loyaltyProgram')}>
                        <div className="row justify-content-between px-2 pt-3">
                            <div className="col">
                                <p className="rewards-subtitle">What is the loyalty programme about?</p>
                            </div>
                            <div className="col-2">
                                <img className="faq-arrow-icon" src={faqArrow} alt="faqArrow" />
                            </div>
                        </div>
                    </div>
                    <div className="faq-card mt-2" onClick={() => navigate('/help/redeemReward')}>
                        <div className="row justify-content-between px-2 pt-3">
                            <div className="col">
                                <p className="rewards-subtitle">How do I redeem my rewards?</p>
                            </div>
                            <div className="col-2">
                                <img className="faq-arrow-icon" src={faqArrow} alt="faqArrow" />
                            </div>
                        </div>
                    </div>
                    <div className="faq-card mt-2" onClick={() => navigate('/help/receiveReward')}>
                        <div className="row justify-content-between px-2 pt-3">
                            <div className="col">
                                <p className="rewards-subtitle">How long does it take to receive my rewards?</p>
                            </div>
                            <div className="col-2">
                                <img className="faq-arrow-icon" src={faqArrow} alt="faqArrow" />
                            </div>
                        </div>
                    </div>
                    <div className="faq-card mt-2" onClick={() => navigate('/help/validateReceipt')}>
                        <div className="row justify-content-between px-2 pt-3">
                            <div className="col">
                                <p className="rewards-subtitle">How long does it take to validate my receipt? What is required during the validation process?</p>
                            </div>
                            <div className="col-2">
                                <img className="faq-arrow-icon" src={faqArrow} alt="faqArrow" />
                            </div>
                        </div>
                    </div>
                    <div className="faq-card mt-2" onClick={() => navigate('/help/getReward')}>
                        <div className="row justify-content-between px-2 pt-3">
                            <div className="col">
                                <p className="rewards-subtitle">What are some of the rewards I can get from participating the loyalty programme?</p>
                            </div>
                            <div className="col-2">
                                <img className="faq-arrow-icon" src={faqArrow} alt="faqArrow" />
                            </div>
                        </div>
                    </div>
                    <div className="faq-card mt-2" onClick={() => navigate('/help/amountPurchase')}>
                        <div className="row justify-content-between px-2 pt-3">
                            <div className="col">
                                <p className="rewards-subtitle">Is there any minimum amount of purchase to validate the receipt?</p>
                            </div>
                            <div className="col-2">
                                <img className="faq-arrow-icon" src={faqArrow} alt="faqArrow" />
                            </div>
                        </div>
                    </div>
                    <div className="faq-card mt-2" onClick={() => navigate('/help/contact')}>
                        <div className="row justify-content-between px-2 pt-3">
                            <div className="col">
                                <p className="rewards-subtitle">Who should I contact should I have any queries with regards to Abbott FreeStyle products?</p>
                            </div>
                            <div className="col-2">
                                <img className="faq-arrow-icon" src={faqArrow} alt="faqArrow" />
                            </div>
                        </div>
                    </div>

                    <p className="rewards-title text-center pt-5">Alternatively, you can also contact us via WhatsApp, available from</p>
                    <p className="receipt-product-purchased text-center pt-3">Monday to Friday, 9AM to 5PM</p>

                    <div className="px-4 pb-3">
                        <a className="btn btn-primary form-control mt-3 submit-btn" href="http://wa.link/4z1755">ENQUIRE NOW</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        token: state.user.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkToken: () => dispatch(checkToken()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Help);