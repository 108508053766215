import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// import Welcome from "./pages/user/welcome";
import Main from "./pages/user/main";
import Login from "./pages/user/login";
import Register from "./pages/user/register";
import Verification from "./pages/user/verification";
import Terms from "./pages/user/terms";
import Homepage from "./pages/user/homepage";
import UploadReceipt from "./pages/user/uploadReceipt";
import UploadReceiptImage from "./pages/user/uploadReceiptImage";
import RewardsRedemption from "./pages/user/rewardsRedemption";
import Rewards from "./pages/user/rewards";
import ClaimRewards from "./pages/user/claimRewards";
import TngRedemption from "./pages/user/tngRedemption";
import AddAddress from "./pages/user/addAddress";
import OurProducts from "./pages/user/ourProducts";
import Help from "./pages/user/help";
import HelpLoyaltyProgram from "./pages/user/helpLoyaltyProgram";
import HelpRedeemReward from "./pages/user/helpRedeemReward";
import HelpReceiveReward from "./pages/user/helpReceiveReward";
import HelpValidateReceipt from "./pages/user/helpValidateReceipt";
import HelpGetReward from "./pages/user/helpGetReward";
import HelpAmountPurchase from "./pages/user/helpAmountPurchase";
import HelpContact from "./pages/user/helpContact";

import AdminLogin from "./pages/admin/adminLogin";
import ReceiptValidation from "./pages/admin/receiptValidation";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route exact path="/" element={<Welcome />} /> */}
        <Route exact path="/" element={<Main />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/verification" element={<Verification />} />
        <Route exact path="/termsConditions" element={<Terms />} />

        <Route exact path="/homepage" element={<Homepage />} />
        <Route exact path="/uploadReceipt" element={<UploadReceipt />} />
        <Route exact path="/uploadReceiptImage" element={<UploadReceiptImage />} />
        <Route exact path="/rewardsRedemption" element={<RewardsRedemption />} />
        <Route exact path="/rewards" element={<Rewards />} />
        <Route exact path="/claimRewards/:reward" element={<ClaimRewards />} />
        <Route exact path="/tngRedemption" element={<TngRedemption />} />
        <Route exact path="/addAddress" element={<AddAddress />} />
        <Route exact path="/ourProducts" element={<OurProducts />} />
        <Route exact path="/help" element={<Help />} />
        <Route exact path="/help/loyaltyProgram" element={<HelpLoyaltyProgram />} />
        <Route exact path="/help/redeemReward" element={<HelpRedeemReward />} />
        <Route exact path="/help/receiveReward" element={<HelpReceiveReward />} />
        <Route exact path="/help/validateReceipt" element={<HelpValidateReceipt />} />
        <Route exact path="/help/getReward" element={<HelpGetReward />} />
        <Route exact path="/help/amountPurchase" element={<HelpAmountPurchase />} />
        <Route exact path="/help/contact" element={<HelpContact />} />

        <Route exact path="/admin/login" element={<AdminLogin />} />
        <Route exact path="/admin/receiptValidation" element={<ReceiptValidation />} />

        <Route path="*" element={<Navigate to ="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
