import React, { useState } from "react";
import { Spinner } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { verification } from "../../redux/actions/userActions";

// components
import WelcomeLogo from "./components/welcomeLogo";

const Verification = ({ isLoading, error, phoneNumber, verification, user }) => {

    const [verificationCode, setVerificationCode] = useState("");

    const handleSubmit = e => {
        e.preventDefault();

        verification({ number: phoneNumber, otp: verificationCode });
    }

    if (user) {
        return <Navigate to="/homepage" />
    } else {
        return (
            <div className="welcome-container">
                <WelcomeLogo />
                <div className="px-5">
                    <p className="auth-title">VERIFICATION CODE</p>
                    <p className="auth-subtitle">Please enter the verification code sent at +6{phoneNumber}</p>
                    <form onSubmit={handleSubmit}>
                        <input type="number" className="form-control" id="verificationCode" name="verificationCode" placeholder="Verification Code" value={verificationCode} onChange={e => setVerificationCode(e.target.value)} required />

                        {
                            error ? (
                                <div>
                                    <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                                </div>
                            ) : null
                        }

                        {
                            isLoading ? <Spinner style={{ display: "block", margin: "auto", marginTop: "5px" }} animation="border" variant="primary" /> : <input type="submit" value="CONFIRM" className="btn btn-primary form-control mt-3 submit-btn" />
                        }

                    </form>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        isLoading: state.user.isLoading,
        error: state.user.error,
        phoneNumber: state.user.phoneNumber,
        user: state.user.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        verification: data => dispatch(verification(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Verification);