import React, { useState, useEffect, useRef } from "react";
import { Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkToken } from "../../redux/actions/userActions";
import axios from 'axios';
import Resizer from 'react-image-file-resizer';

// components
import Topbar from "./components/topbar";

// images
import sucess from "../../assets/image/success.png";

const UploadReceiptImage = ({ user, token, phoneNumber, checkToken }) => {

    const navigate = useNavigate();
    const uploadFileClick = useRef();

    let formData = JSON.parse(localStorage.getItem("formData"));

    const [show, setShow] = useState(false);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [uri, setUri] = useState("");
    const [fileType, setFileType] = useState("");

    useEffect(() => {
        if (!token) {
            navigate('/login');
        } else if (!formData) {
            navigate('/homepage');
        } else if (!user) {
            checkToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleImage = e => {
        if (e.target.files[0]) {
            setFileType(e.target.files[0].type)
            Resizer.imageFileResizer(
                e.target.files[0],
                1500,
                1500,
                "JPEG",
                30,
                0,
                uri => setUri(uri),
            );
        }
    }

    const handleSubmit = e => {

        setError("");

        if (fileType && uri) {

            setLoading(true);

            formData.uri = uri;
            formData.filetype = fileType;
            formData.number = phoneNumber;

            axios.post('/api/transaction/recordTrans', formData)
                .then(res => {
                    setShow(true);
                    setLoading(false);
                    localStorage.removeItem("formData");
                })
                .catch(err => {
                    setError(err.response.data.error);
                    setLoading(false);
                });
        } else {
            setError("Please upload your receipt");
        }
    }

    const handleClose = () => {
        setShow(false);
        navigate('/homepage');
    }

    const handleUploadInputClick = () => {
        uploadFileClick.current.click();
    }

    return (
        <div>
            <Topbar />
            <div className="container">
                <p className="page-title">UPLOAD RECEIPT</p>
                <div className="px-3 pt-1">
                    <p><b>UPLOAD PREVIEW</b></p>

                    <div className="receipt-image-card">
                        <div className="px-3 py-3">
                            {uri ? <img src={uri} alt="receiptImage" className="welcome-product-img" style={{ maxWidth: "250px", maxHeight: "250px" }} /> : null}
                            <input hidden type="file" accept="image/*" onChange={handleImage} ref={uploadFileClick} />
                            <button type="button" className="btn btn-primary form-control grey-btn mt-3" onClick={handleUploadInputClick}>{uri ? "RESCAN" : "SCAN"}</button>
                        </div>
                    </div>

                    <p className="pt-5"><b>MAKE SURE</b></p>
                    <div className="d-flex">
                        <img src={sucess} alt="sucess" className="upload-receipt-icon" />
                        <p className="ps-2">The frame covers the whole receipt.</p>
                    </div>
                    <div className="d-flex pt-2">
                        <img src={sucess} alt="sucess" className="upload-receipt-icon" />
                        <p className="ps-2">The wording especially receipt number can be seen clearly.</p>
                    </div>
                    <div className="d-flex pt-2">
                        <img src={sucess} alt="sucess" className="upload-receipt-icon" />
                        <p className="ps-2">It is a valid receipt with transaction done in-store or offline.</p>
                    </div>

                    {
                        error ? (
                            <div>
                                <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                            </div>
                        ) : null
                    }

                    {
                        loading ? <Spinner style={{ display: "block", margin: "auto", marginTop: "5px" }} animation="border" variant="primary" /> : <button type="button" className="btn btn-primary form-control mt-3 submit-btn" onClick={() => handleSubmit()}>SUBMIT</button>
                    }

                </div>
            </div>

            <Modal show={show} onHide={handleClose} backdrop="static" centered backdropClassName="modal-back-drop">
                <Modal.Body>
                    <img src={sucess} alt="sucess" className="welcome-product-img" />
                    <p className="welcome-title">RECEIPT UPLOAD SUCCESSFUL</p>
                    <p className="text-center">You have successfully uploaded your receipt. You're one step away from being able to claim the rewards. The receipt validation will be reflected in <b>3-5 working days</b></p>
                    <div className="px-4">
                        <button type="button" className="btn btn-primary form-control mt-3 orange-btn" onClick={() => handleClose()}>OKAY</button>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        token: state.user.token,
        phoneNumber: state.user.phoneNumber
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkToken: () => dispatch(checkToken()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadReceiptImage);