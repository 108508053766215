import React, { useState } from "react";
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// components
import WelcomeLogo from "./components/welcomeLogo";

const Register = ({ phoneNumber, channel }) => {

    const [fullName, setFullName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [agree, setAgree] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = e => {
        e.preventDefault();

        if (!fullName && !emailAddress && !phoneNumber) {
            setError("Please fill in your name and email to register");
        } else if (!/^[A-Za-z\s]+$/.test(fullName)) {
            setError("Full name should only contain alphabet and space");
        } else {
            setError("");
            setLoading(true);
            axios.post('/api/auth/register', { number: phoneNumber, email: emailAddress, name: fullName, channel })
                .then(res => {
                    navigate('/verification');
                    setLoading(false);
                })
                .catch(err => {
                    setError(err.response.data.error);
                    setLoading(false);
                });
        }
    }

    return (
        <div className="welcome-container">
            <WelcomeLogo />
            <div className="px-5">
                <p className="auth-title">REGISTRATION</p>
                <p className="auth-subtitle">Please enter your name &amp; email address to register</p>
                <form onSubmit={handleSubmit}>
                    <input type="text" className="form-control" id="fullName" name="fullName" placeholder="Full Name" value={fullName} onChange={e => setFullName(e.target.value)} required />
                    <input type="email" className="form-control mt-2" id="emailAddress" name="emailAddress" placeholder="Email Address" value={emailAddress} onChange={e => setEmailAddress(e.target.value)} required />

                    <div className="form-group form-check pt-3">
                        <input type="checkbox" id="agree" name="agree" className="form-check-input" value={agree} onChange={e => setAgree(e.target.value)} required />
                        <label htmlFor="agree" className="form-check-label">
                            <small>I have read and agree to the <Link to="/termsConditions">Terms &amp; Condition</Link></small>
                        </label>
                    </div>

                    {
                        error ? (
                            <div>
                                <p style={{ color: "red", textAlign: "center", paddingTop: "5px" }}>{error}</p>
                            </div>
                        ) : null
                    }

                    {
                        loading ? <Spinner style={{ display: "block", margin: "auto", marginTop: "5px" }} animation="border" variant="primary" /> : <input type="submit" value="REGISTER" className="btn btn-primary form-control mt-3 submit-btn" />
                    }
                </form>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        phoneNumber: state.user.phoneNumber,
        channel: state.user.channel
    };
};

export default connect(mapStateToProps, null)(Register);