import React, { useEffect, useRef, useState } from "react";
import { Carousel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkToken } from "../../redux/actions/userActions";

// components
import Topbar from "./components/topbar";
import HowItWorks from './components/howItWorks';

// image
import uploadReceiptIcon from "../../assets/image/uploadReceiptIcon.png";
import rewardsRedemptionIcon from "../../assets/image/rewardsRedemptionIcon.png";
import productIcon from "../../assets/image/freestyle_logo.png";
import helpdeskIcon from "../../assets/image/helpdeskIcon.png";

// banner video
import banner1 from "../../assets/video/banner1.mp4";
import banner2 from "../../assets/video/banner2.mp4";
import banner3 from "../../assets/video/banner3.mp4";

const Homepage = ({ user, token, checkToken }) => {

    const navigate = useNavigate();
    const bannerVideo1 = useRef();
    const bannerVideo2 = useRef();
    const bannerVideo3 = useRef();
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
        bannerVideo1.current.pause();
        bannerVideo2.current.pause();
        bannerVideo3.current.pause();
    };

    useEffect(() => {
        if (!token) {
            navigate('/login');
        } else if (!user) {
            checkToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Topbar />

            <Carousel controls={false} interval={null} className="text-center" activeIndex={index} onSelect={handleSelect}>
                <Carousel.Item>
                    <p>Introduction of Freestyle Libre System</p>
                    <video src={banner1} width="300px" controls ref={bannerVideo1}>
                    </video>
                </Carousel.Item>
                <Carousel.Item>
                    <p style={{ fontSize: "12px" }}>How does the Sensor Reading compare to Blood Glucose Reading</p>
                    <video src={banner2} width="300px" controls ref={bannerVideo2}>
                    </video>
                </Carousel.Item>
                <Carousel.Item>
                    <p>Specification of how the sensory was made</p>
                    <video src={banner3} width="300px" controls ref={bannerVideo3}>
                    </video>
                </Carousel.Item>
            </Carousel>

            <div className="px-4 py-5">
                <div className="homepage-card px-3 py-3" onClick={() => navigate('/uploadReceipt')}>
                    <img src={uploadReceiptIcon} alt="uploadReceiptIcon" className="homepage-card-img" />
                    <p className="ps-5"><b>UPLOAD RECEIPT</b></p>
                </div>
                <div className="homepage-card mt-3 px-3 py-3" onClick={() => navigate('/rewardsRedemption')}>
                    <img src={rewardsRedemptionIcon} alt="rewardsRedemptionIcon" className="homepage-card-img" />
                    <p className="ps-5"><b>REWARDS REDEMPTION</b></p>
                </div>
                <div className="homepage-card mt-3 px-3 py-3" onClick={() => navigate('/ourProducts')}>
                    <img src={productIcon} alt="productIcon" className="homepage-card-logo" />
                    <p className="ps-4"><b>PRODUCT INFORMATION</b></p>
                </div>
                <div className="homepage-card mt-3 px-3 py-3" onClick={() => navigate('/help')}>
                    <img src={helpdeskIcon} alt="helpdeskIcon" className="homepage-card-img" />
                    <p className="ps-5"><b>HELP DESK</b></p>
                </div>
            </div>

            <HowItWorks />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        token: state.user.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkToken: () => dispatch(checkToken()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);