import React, { useState, useEffect } from "react";
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkToken } from "../../redux/actions/userActions";

// components
import Topbar from "./components/topbar";

// images
import sensorImg from "../../assets/image/sensor.png";
import stripsImg from "../../assets/image/optiumNeo.png";
import liteStripsImg from "../../assets/image/liteStrips.jpg";
import plusIcon from "../../assets/image/plus.png";
import minusIcon from "../../assets/image/minus.png";

const UploadReceipt = ({ user, token, checkToken }) => {

    const navigate = useNavigate();

    const [receiptNumber, setReceiptNumber] = useState("");
    const [receiptDate, setReceiptDate] = useState("");
    const [amount, setAmount] = useState("");
    const [place, setPlace] = useState("");
    const [state, setState] = useState("");
    const [sensor, setSensor] = useState(0);
    const [strips, setStrips] = useState(0);
    const [liteStrips, setLiteStrips] = useState(0);
    const [show, setShow] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        if (!token) {
            navigate('/login');
        } else if (!user) {
            checkToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const plusSensor = () => {
        let sensorVal = sensor;
        sensorVal++;
        setSensor(sensorVal);
    }

    const minusSensor = () => {
        let sensorVal = sensor;
        if (sensorVal !== 0) {
            sensorVal--;
            setSensor(sensorVal);
        }
    }

    const plusStrips = () => {
        let stripsVal = strips;
        stripsVal++;
        setStrips(stripsVal);
    }

    const minusStrips = () => {
        let stripsVal = strips;
        if (stripsVal !== 0) {
            stripsVal--;
            setStrips(stripsVal);
        }
    }

    const plusLiteStrips = () => {
        let liteStripsVal = liteStrips;
        liteStripsVal++;
        setLiteStrips(liteStripsVal);
    }

    const minusLiteStrips = () => {
        let liteStripsVal = liteStrips;
        if (liteStripsVal !== 0) {
            liteStripsVal--;
            setLiteStrips(liteStripsVal);
        }
    }

    const handleSubmit = e => {
        setError("");
        e.preventDefault();

        if (!state) {
            setError("Please choose the state")
        } else {
            if (sensor || strips || liteStrips) {
                setShow(true);
            } else {
                setError("Please choose your purchased product")
            }
        }

    }

    const handleClose = () => setShow(false);

    const submitReceipt = () => {
        setShow(false);

        const formData = {
            receiptNum: receiptNumber,
            receiptDate: receiptDate,
            amount: amount,
            place: place,
            state: state,
            sensor: sensor,
            strip: strips,
            liteStrip: liteStrips
        }

        localStorage.setItem("formData", JSON.stringify(formData));

        navigate('/uploadReceiptImage');
    }

    return (
        <div>
            <Topbar />
            <div className="container">
                <p className="page-title">UPLOAD RECEIPT</p>
                <div className="px-3 pt-1">
                    <form onSubmit={handleSubmit}>
                        <label><b>RECEIPT NUMBER</b></label>
                        <input type="text" className="form-control input-fields" id="receiptNumber" name="receiptNumber" placeholder="Enter Receipt Number" value={receiptNumber} onChange={e => setReceiptNumber(e.target.value)} required />
                        <label className="pt-3"><b>RECEIPT DATE</b></label>
                        <input type="date" className="form-control input-fields" id="receiptDate" name="receiptDate" placeholder="Enter Receipt Date" value={receiptDate} onChange={e => setReceiptDate(e.target.value)} required />
                        <label className="pt-3"><b>AMOUNT</b></label>
                        <input type="number" step="any" className="form-control input-fields" id="amount" name="amount" placeholder="Enter Amount" value={amount} onChange={e => setAmount(e.target.value)} required />
                        <label className="pt-3"><b>PLACE OF PURCHASE</b></label>
                        <input type="text" className="form-control input-fields" id="place" name="place" placeholder="Enter Place of Purchase" value={place} onChange={e => setPlace(e.target.value)} required />
                        <label className="pt-3"><b>STATE</b></label>

                        <select className="form-select input-fields" id="state" name="state" placeholder="Select State" value={state} onChange={e => setState(e.target.value)} required>
                            <option>Select State</option>
                            <option value="Johor">Johor</option>
                            <option value="Kedah">Kedah</option>
                            <option value="Kelantan">Kelantan</option>
                            <option value="Kuala Lumpur">Kuala Lumpur</option>
                            <option value="Labuan">Labuan</option>
                            <option value="Melaka">Melaka</option>
                            <option value="Negeri Sembilan">Negeri Sembilan</option>
                            <option value="Pahang">Pahang</option>
                            <option value="Penang">Penang</option>
                            <option value="Perak">Perak</option>
                            <option value="Perlis">Perlis</option>
                            <option value="Putrajaya">Putrajaya</option>
                            <option value="Sabah">Sabah</option>
                            <option value="Sarawak">Sarawak</option>
                            <option value="Selangor">Selangor</option>
                            <option value="Terengganu">Terengganu</option>
                        </select>

                        <label className="pt-3"><b>CHOOSE YOUR PURCHASED PRODUCT</b></label>
                        <div className="d-flex">
                            <div className="px-1 py-1 receipt-product-container">
                                <img src={sensorImg} alt="sensor" className="welcome-product-img" style={{ maxWidth: "150px", maxHeight: "150px" }} />
                                <p className="auth-subtitle">FreeStyle Libre Sensor</p>
                                <div className="row justify-content-between">
                                    <img src={plusIcon} alt="plusIcon" className="upload-receipt-icon col" onClick={e => plusSensor()} />
                                    <p className="col text-center"><b>{sensor}</b></p>
                                    <img src={minusIcon} alt="minusIcon" className="upload-receipt-icon col" onClick={e => minusSensor()} />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="px-1 py-1 receipt-product-container">
                                <img src={stripsImg} alt="strips" className="welcome-product-img" style={{ maxWidth: "150px", maxHeight: "150px" }} />
                                <p className="auth-subtitle">FreeStyle Optium Neo Strips 50s</p>
                                <div className="row justify-content-between">
                                    <img src={plusIcon} alt="plusIcon" className="upload-receipt-icon col" onClick={e => plusStrips()} />
                                    <p className="col text-center"><b>{strips}</b></p>
                                    <img src={minusIcon} alt="minusIcon" className="upload-receipt-icon col" onClick={e => minusStrips()} />
                                </div>
                            </div>
                            <div className="px-1 py-1 receipt-product-container">
                                <img src={liteStripsImg} alt="liteStrips" className="welcome-product-img" style={{ maxWidth: "150px", maxHeight: "150px" }} />
                                <p className="auth-subtitle">FreeStyle Lite Strips 50s</p>
                                <div className="row justify-content-between">
                                    <img src={plusIcon} alt="plusIcon" className="upload-receipt-icon col" onClick={e => plusLiteStrips()} />
                                    <p className="col text-center"><b>{liteStrips}</b></p>
                                    <img src={minusIcon} alt="minusIcon" className="upload-receipt-icon col" onClick={e => minusLiteStrips()} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <p style={{ color: "red", textAlign: "center" }}>{error ? error : null}</p>
                        </div>
                        <div className="px-4 pb-3">
                            <input type="submit" value="PROCEED" className="btn btn-primary form-control submit-btn" />
                        </div>
                    </form>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} backdrop="static" centered backdropClassName="modal-back-drop">
                <Modal.Body>
                    <p className="welcome-title">CONFIRMATION</p>
                    <div className="row justify-content-between">
                        <p className="col"><b>RECEIPT NUMBER</b></p>
                        <p className="receipt-confirmation-details col">{receiptNumber}</p>
                    </div>
                    <div className="row justify-content-between">
                        <p className="col"><b>RECEIPT DATE</b></p>
                        <p className="receipt-confirmation-details col">{new Date(receiptDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}</p>
                    </div>
                    <div className="row justify-content-between">
                        <p className="col"><b>AMOUNT</b></p>
                        <p className="receipt-confirmation-details col">RM{amount}</p>
                    </div>
                    <div className="row justify-content-between">
                        <p className="col"><b>PLACE OF PURCHASE</b></p>
                        <p className="receipt-confirmation-details col">{place}</p>
                    </div>
                    <div className="row justify-content-between">
                        <p className="col"><b>STATE</b></p>
                        <p className="receipt-confirmation-details col">{state}</p>
                    </div>
                    <br />
                    <div className="row justify-content-between">
                        <p className="col-10"><b>PRODUCT PURCHASED</b></p>
                        <p className="col">Qty</p>
                    </div>
                    {
                        sensor ? (
                            <div className="row justify-content-between">
                                <p className="receipt-product-purchased col-10">FreeStyle Libre Sensor</p>
                                <p className="col"><b>{sensor}x</b></p>
                            </div>
                        ) : null
                    }
                    {
                        strips ? (
                            <div className="row justify-content-between">
                                <p className="receipt-product-purchased col-10">FreeStyle Optium Neo Strips 50s</p>
                                <p className="col"><b>{strips}x</b></p>
                            </div>
                        ) : null
                    }
                    {
                        liteStrips ? (
                            <div className="row justify-content-between">
                                <p className="receipt-product-purchased col-10">FreeStyle Lite Strips 50s</p>
                                <p className="col"><b>{liteStrips}x</b></p>
                            </div>
                        ) : null
                    }
                    <br />
                    <div className="px-4">
                        <Button onClick={() => handleClose()} className="btn btn-primary form-control grey-btn">EDIT</Button>
                        <Button onClick={() => submitReceipt()} className="btn btn-primary form-control mt-3 orange-btn">CONFIRM</Button>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        token: state.user.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkToken: () => dispatch(checkToken()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadReceipt);