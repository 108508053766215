import React, { useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from "query-string";

import { connect } from 'react-redux';
import { saveChannel } from "../../redux/actions/userActions";

import abbottLogo from "../../assets/image/abbott_logo.png";
import freeStyleLogo from "../../assets/image/freestyle_logo.png";

const Main = ({ saveChannel }) => {

    const navigate = useNavigate();

    const location = useLocation();
    const { utm_source } = queryString.parse(location.search);

    useEffect(() => {
        if (utm_source) {
            saveChannel(utm_source);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="welcome-container">
            <img src={abbottLogo} alt="abbottLogo" className="welcome-product-img pt-5" />
            <img src={freeStyleLogo} alt="freeStyleLogo" className="welcome-logo" />
            <p className="main-title mt-3"><b>Please Select</b></p>
            <div className="px-5">
                <a href="https://groupeducation.freestyleprivilege.com/" className="btn btn-primary form-control main-button mt-5 py-3">GROUP EDUCATION</a>
                <button className="btn btn-primary form-control main-button mt-4 py-3" onClick={() => navigate("/login")}>FREESTYLE PRIVILEGE</button>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        saveChannel: data => dispatch(saveChannel(data))
    };
};

export default connect(null, mapDispatchToProps)(Main);