import React, { useState, useEffect } from "react";
import axios from 'axios';
import { CSVLink } from 'react-csv';
import { MDBDataTable } from 'mdbreact';
import moment from 'moment';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { connect } from 'react-redux';
import { Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { adminLogout } from "../../redux/actions/adminActions";

import Sidebar from "./components/adminSidebar";
import AdminTopbar from "./components/adminTopbar";

const mySwal = withReactContent(Swal);

const ReceiptValidation = ({ token, adminLogout }) => {

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [selected, setSelected] = useState("pending");
    const [receiptData, setReceiptData] = useState(null);
    const [id, setId] = useState("");
    const [loading, setLoading] = useState(false);

    const [receiptNum, setReceiptNum] = useState("");
    const [receiptDate, setReceiptDate] = useState("");
    const [amount, setAmount] = useState("");
    const [sensor, setSensor] = useState("");
    const [strip, setStrip] = useState("");
    const [liteStrip, setLiteStrip] = useState("");
    const [number, setNumber] = useState("");
    const [place, setPlace] = useState("");
    const [state, setState] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate('/admin/login');
        } else {
            setLoading(true);
            const URL = '/api/admin/validationList?startDate=' + startDate + '&endDate=' + endDate + '&status=' + selected;
            axios.get(URL, {
                headers: {
                    "Content-Type": "application/json",
                    "auth-token": token
                }
            })
                .then(response => {
                    setReceiptData(response.data.data);
                    setLoading(false);
                })
                .catch(err => {
                    if (err.response.data.error === "Token is not valid") {
                        setLoading(false);
                        mySwal.fire("Error", "Your session was expired, please login again", "error")
                            .then(click => {
                                if (click.isConfirmed) {
                                    adminLogout();
                                    navigate('/admin/login');
                                }
                            })
                    } else {
                        setLoading(false);
                        mySwal.fire("Error", err.response.data.error, "error")
                    }
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSelect = status => {
        setLoading(true);
        setSelected(status);
        const URL = '/api/admin/validationList?startDate=' + startDate + '&endDate=' + endDate + '&status=' + status
        axios.get(URL, {
            headers: {
                "Content-Type": "application/json",
                "auth-token": token
            }
        })
            .then(response => {
                setReceiptData(response.data.data);
                setLoading(false);
            })
            .catch(err => {
                if (err.response.data.error === "Token is not valid") {
                    setLoading(false);
                    mySwal.fire("Error", "Your session was expired, please login again", "error")
                        .then(click => {
                            if (click.isConfirmed) {
                                adminLogout();
                                navigate('/admin/login');
                            }
                        })
                } else {
                    setLoading(false);
                    mySwal.fire("Error", err.response.data.error, "error")
                }
            });
    }

    const handleFilter = () => {
        setLoading(true);
        const URL = '/api/admin/validationList?startDate=' + startDate + '&endDate=' + endDate + '&status=' + selected

        axios.get(URL, {
            headers: {
                "Content-Type": "application/json",
                "auth-token": token
            }
        })
            .then(response => {
                setReceiptData(response.data.data);
                setLoading(false);
            })
            .catch(err => {
                if (err.response.data.error === "Token is not valid") {
                    setLoading(false);
                    mySwal.fire("Error", "Your session was expired, please login again", "error")
                        .then(click => {
                            if (click.isConfirmed) {
                                adminLogout();
                                navigate('/admin/login');
                            }
                        })
                } else {
                    setLoading(false);
                    mySwal.fire("Error", err.response.data.error, "error")
                }
            });
    }

    const handleReset = () => {
        setStartDate("");
        setEndDate("");

        setLoading(true);
        const URL = '/api/admin/validationList?status=' + selected
        axios.get(URL, {
            headers: {
                "Content-Type": "application/json",
                "auth-token": token
            }
        })
            .then(response => {
                setReceiptData(response.data.data);
                setLoading(false);
            })
            .catch(err => {
                if (err.response.data.error === "Token is not valid") {
                    setLoading(false);
                    mySwal.fire("Error", "Your session was expired, please login again", "error")
                        .then(click => {
                            if (click.isConfirmed) {
                                adminLogout();
                                navigate('/admin/login');
                            }
                        })
                } else {
                    setLoading(false);
                    mySwal.fire("Error", err.response.data.error, "error")
                }
            });
    }

    const handleApprove = () => {

        if (id && receiptDate && receiptNum && number) {
            setLoading(true);

            const sendThis = {
                action: "approved",
                id,
                receiptDate,
                receiptNum,
                amount,
                sensor,
                strip,
                liteStrip,
                number,
                place,
                state
            };

            axios.post('/api/admin/validate', sendThis, {
                headers: {
                    "Content-Type": "application/json",
                    "auth-token": token
                }
            })
                .then(res => {
                    const URL = '/api/admin/validationList?status=' + selected
                    axios.get(URL, {
                        headers: {
                            "Content-Type": "application/json",
                            "auth-token": token
                        }
                    })
                        .then(response => {
                            setReceiptData(response.data.data);
                            setLoading(false);
                        })
                        .catch(err => {
                            if (err.response.data.error === "Token is not valid") {
                                setLoading(false);
                                mySwal.fire("Error", "Your session was expired, please login again", "error")
                                    .then(click => {
                                        if (click.isConfirmed) {
                                            adminLogout();
                                            navigate('/admin/login');
                                        }
                                    })
                            } else {
                                setLoading(false);
                                mySwal.fire("Error", err.response.data.error, "error")
                            }
                        });
                })
                .catch(err => {
                    if (err.response.data.error === "Token is not valid") {
                        setLoading(false);
                        mySwal.fire("Error", "Your session was expired, please login again", "error")
                            .then(click => {
                                if (click.isConfirmed) {
                                    adminLogout();
                                    navigate('/admin/login');
                                }
                            })
                    } else {
                        setLoading(false);
                        mySwal.fire("Error", err.response.data.error, "error")
                    }
                });

            setId("");
        } else {
            mySwal.fire("Error", "Please fill in all the receipt details", "error")
        }
    }

    const handleReject = () => {
        let sendThis = {
            action: "rejected",
            id,
            receiptDate,
            receiptNum,
            amount,
            sensor,
            strip,
            liteStrip,
            number,
            place,
            state
        };

        setId("");

        mySwal.fire({
            title: "Reason for rejecting receipt",
            input: "text",
            inputAttributes: {
                autocapitalize: "off"
            },
            showCancelButton: true,
            confirmButtonText: "Confirm",
            cancelButtonColor: "#d33",
            showLoaderOnConfirm: true,
            inputValidator: val => {
                if (!val) {
                    return "A reason is required"
                };
            },
            preConfirm: reason => {
                sendThis.reason = reason;
                setId("");
            },
            allowOutsideClick: () => !mySwal.isLoading()
        })
            .then(result => {
                if (result.isConfirmed) {
                    setLoading(true);
                    axios.post('/api/admin/validate', sendThis, {
                        headers: {
                            "Content-Type": "application/json",
                            "auth-token": token
                        }
                    })
                        .then(res => {
                            const URL = '/api/admin/validationList?status=' + selected
                            axios.get(URL, {
                                headers: {
                                    "Content-Type": "application/json",
                                    "auth-token": token
                                }
                            })
                                .then(response => {
                                    setReceiptData(response.data.data);
                                    setLoading(false);
                                })
                                .catch(err => {
                                    if (err.response.data.error === "Token is not valid") {
                                        setLoading(false);
                                        mySwal.fire("Error", "Your session was expired, please login again", "error")
                                            .then(click => {
                                                if (click.isConfirmed) {
                                                    adminLogout();
                                                    navigate('/admin/login');
                                                }
                                            })
                                    } else {
                                        setLoading(false);
                                        mySwal.fire("Error", err.response.data.error, "error")
                                    }
                                });
                        })
                        .catch(err => {
                            if (err.response.data.error === "Token is not valid") {
                                setLoading(false);
                                mySwal.fire("Error", "Your session was expired, please login again", "error")
                                    .then(click => {
                                        if (click.isConfirmed) {
                                            adminLogout();
                                            navigate('/admin/login');
                                        }
                                    })
                            } else {
                                setLoading(false);
                                mySwal.fire("Error", err.response.data.error, "error")
                            }
                        });
                }
            })
    }

    const renderColor = text => {
        if (text === selected) {
            return { width: "120px", backgroundColor: "#5bb75b", fontWeight: "bold" };
        } else {
            return { width: "120px" };
        }
    };

    let data = {
        columns: [
            {
                field: "name",
                label: "Name",
                sort: "asc"
            },
            {
                field: "number",
                label: "Phone Number",
                sort: "asc"
            },
            {
                field: "receiptNum",
                label: "Receipt Number",
                sort: "asc"
            },
            {
                field: "receiptImage",
                label: "Receipt Image",
                sort: "asc"
            },
            {
                field: "amount",
                label: "Receipt Amount",
                sort: "asc"
            },
            {
                field: "receiptDate",
                label: "Receipt Date",
                sort: "asc"
            },
            {
                field: "sensor",
                label: "Sensor",
                sort: "asc"
            },
            {
                field: "strip",
                label: "Strip",
                sort: "asc"
            },
            {
                field: "liteStrip",
                label: "Lite Strip",
                sort: "asc"
            },
            {
                field: "uploadDate",
                label: "Upload Date",
                sort: "asc"
            },
            {
                field: "place",
                label: "Place of Purchase",
                sort: "asc"
            },
            {
                field: "state",
                label: "State",
                sort: "asc"
            },
            // {
            //     field: "week",
            //     label: "Week",
            //     sort: "asc"
            // }
        ],
        rows: []
    };

    let csvData = {
        headers: [
            {
                key: "name",
                label: "Name"
            },
            {
                key: "number",
                label: "Phone Number"
            },
            {
                key: "receiptNum",
                label: "Receipt Number"
            },
            {
                key: "receiptImage",
                label: "Receipt Image"
            },
            {
                key: "amount",
                label: "Receipt Amount"
            },
            {
                key: "receiptDate",
                label: "Receipt Date"
            },
            {
                key: "sensor",
                label: "Sensor"
            },
            {
                key: "strip",
                label: "Strip"
            },
            {
                key: "liteStrip",
                label: "Lite Strip"
            },
            {
                key: "uploadDate",
                label: "Upload Date"
            },
            {
                key: "place",
                label: "Place of Purchase"
            },
            {
                key: "state",
                label: "State"
            },
            // {
            //     key: "week",
            //     label: "Week"
            // }
        ],
        data: []
    };

    const today = new Date();
    const csvDate = `${today.getFullYear()}_${today.getMonth() + 1}_${today.getDate()}`;

    // const getWeek = date => {
    //     const today = new Date(date);
    //     const firstDayOfYear = new Date("09/01/2021");
    //     const pastDaysOfYear = ((today - firstDayOfYear) / 86400000);
    //     const answer = Math.ceil((pastDaysOfYear + firstDayOfYear.getDay()) / 7)
    //     if (answer > 0) {
    //         return answer;
    //     }
    //     else {
    //         return "NA";
    //     }
    // };

    if (selected === "pending" /* || selected === "issue" */) {
        data.columns.push(
            {
                label: "Action",
                field: "action",
                sort: "asc"
            }
        );
        if (receiptData) {
            for (let i = 0; i < receiptData.length; i++) {
                const validationObject = {
                    name: receiptData[i].name,
                    number: receiptData[i].number,
                    receiptNum: receiptData[i].receiptNum,
                    receiptImage: <img src={receiptData[i].receiptImage} width="30px" alt="receiptImage"></img>,
                    amount: receiptData[i].amount,
                    receiptDate: moment(new Date(receiptData[i].receiptDate)).format("DD/MM/YYYY"),
                    sensor: receiptData[i].sensor,
                    strip: receiptData[i].strip,
                    liteStrip: receiptData[i].liteStrip,
                    uploadDate: moment(new Date(receiptData[i].uploadDate)).format("DD/MM/YYYY"),
                    place: receiptData[i].place,
                    state: receiptData[i].state,
                    // week: getWeek(new Date(receiptData[i].receiptDate)),
                    action: <button type="button" className="btn btn-dark"
                        onClick={() => {
                            setId(receiptData[i].id);
                            setReceiptNum(receiptData[i].receiptNum);
                            setReceiptDate(moment(new Date(receiptData[i].receiptDate)).format("YYYY-MM-DD"));
                            setAmount(receiptData[i].amount);
                            setSensor(receiptData[i].sensor);
                            setStrip(receiptData[i].strip);
                            setLiteStrip(receiptData[i].liteStrip);
                            setNumber(receiptData[i].number);
                            setPlace(receiptData[i].place);
                            setState(receiptData[i].state);
                        }}
                    >Action</button>
                };

                const csvObject = {
                    name: receiptData[i].name,
                    number: `=""${receiptData[i].number}""`,
                    receiptNum: receiptData[i].receiptNum,
                    receiptImage: <img src={receiptData[i].receiptImage} width="30px" alt="receiptImage"></img>,
                    amount: receiptData[i].amount,
                    receiptDate: moment(new Date(receiptData[i].receiptDate)).format("DD/MM/YYYY"),
                    sensor: receiptData[i].sensor,
                    strip: receiptData[i].strip,
                    liteStrip: receiptData[i].liteStrip,
                    uploadDate: moment(new Date(receiptData[i].uploadDate)).format("DD/MM/YYYY"),
                    place: receiptData[i].place,
                    state: receiptData[i].state
                    // week: getWeek(new Date(receiptData[i].receiptDate)),
                };

                csvData.data.push(csvObject);
                data.rows.push(validationObject);
            }
        }

    } else if (selected === "approved") {
        // approved
        if (receiptData) {
            for (let i = 0; i < receiptData.length; i++) {
                const validationObject = {
                    name: receiptData[i].name,
                    number: receiptData[i].number,
                    receiptNum: receiptData[i].receiptNum,
                    receiptImage: <img src={receiptData[i].receiptImage} width="30px" alt="receiptImage"></img>,
                    amount: receiptData[i].amount,
                    receiptDate: moment(new Date(receiptData[i].receiptDate)).format("DD/MM/YYYY"),
                    sensor: receiptData[i].sensor,
                    strip: receiptData[i].strip,
                    liteStrip: receiptData[i].liteStrip,
                    uploadDate: moment(new Date(receiptData[i].uploadDate)).format("DD/MM/YYYY"),
                    place: receiptData[i].place,
                    state: receiptData[i].state
                    // week: getWeek(new Date(receiptData[i].receiptDate)),
                };

                const csvObject = {
                    name: receiptData[i].name,
                    number: `=""${receiptData[i].number}""`,
                    receiptNum: receiptData[i].receiptNum,
                    receiptImage: <img src={receiptData[i].receiptImage} width="30px" alt="receiptImage"></img>,
                    amount: receiptData[i].amount,
                    receiptDate: moment(new Date(receiptData[i].receiptDate)).format("DD/MM/YYYY"),
                    sensor: receiptData[i].sensor,
                    strip: receiptData[i].strip,
                    liteStrip: receiptData[i].liteStrip,
                    uploadDate: moment(new Date(receiptData[i].uploadDate)).format("DD/MM/YYYY"),
                    place: receiptData[i].place,
                    state: receiptData[i].state
                    // week: getWeek(new Date(receiptData[i].receiptDate)),
                };

                csvData.data.push(csvObject);
                data.rows.push(validationObject);
            };
        }
    } else if (selected === "rejected") {
        // rejected
        data.columns.push(
            // {
            //     label: "Action",
            //     field: "action",
            //     sort: "asc"
            // },
            {
                label: "Reason",
                field: "reason",
                sort: "asc"
            }
        );
        csvData.headers.push({
            key: "reason",
            label: "Reason"
        })
        if (receiptData) {
            for (let i = 0; i < receiptData.length; i++) {
                const validationObject = {
                    name: receiptData[i].name,
                    number: receiptData[i].number,
                    receiptNum: receiptData[i].receiptNum,
                    receiptImage: <img src={receiptData[i].receiptImage} width="30px" alt="receiptImage"></img>,
                    amount: receiptData[i].amount,
                    receiptDate: moment(new Date(receiptData[i].receiptDate)).format("DD/MM/YYYY"),
                    sensor: receiptData[i].sensor,
                    strip: receiptData[i].strip,
                    liteStrip: receiptData[i].liteStrip,
                    uploadDate: moment(new Date(receiptData[i].uploadDate)).format("DD/MM/YYYY"),
                    place: receiptData[i].place,
                    state: receiptData[i].state,
                    // week: getWeek(new Date(receiptData[i].receiptDate)),
                    reason: receiptData[i].reason ? receiptData[i].reason : "",
                    // action: <button type="button" className="btn btn-dark"
                    //     onClick={() => {
                    //         setId(receiptData[i].id);
                    //         setReceiptNum(receiptData[i].receiptNum);
                    //         setReceiptImage(receiptData[i].receiptImage);
                    //         setReceiptDate(moment(new Date(receiptData[i].receiptDate)).format("YYYY-MM-DD"));
                    //         setAmount(receiptData[i].amount);
                    //         setSensor(receiptData[i].sensor);
                    //         setStrip(receiptData[i].strip);
                    //         setLiteStrip(receiptData[i].liteStrip)
                    //         setPlace(receiptData[i].place)
                    //         setState(receiptData[i].state)
                    //     }}
                    // >Action</button>
                };

                const csvObject = {
                    name: receiptData[i].name,
                    number: `=""${receiptData[i].number}""`,
                    receiptNum: receiptData[i].receiptNum,
                    receiptImage: <img src={receiptData[i].receiptImage} width="30px" alt="receiptImage"></img>,
                    amount: receiptData[i].amount,
                    receiptDate: moment(new Date(receiptData[i].receiptDate)).format("DD/MM/YYYY"),
                    sensor: receiptData[i].sensor,
                    strip: receiptData[i].strip,
                    liteStrip: receiptData[i].liteStrip,
                    uploadDate: moment(new Date(receiptData[i].uploadDate)).format("DD/MM/YYYY"),
                    place: receiptData[i].place,
                    state: receiptData[i].state,
                    // week: getWeek(new Date(receiptData[i].receiptDate)),
                    reason: receiptData[i].reason ? receiptData[i].reason : ""
                };

                csvData.data.push(csvObject);
                data.rows.push(validationObject);
            };
        }
    }

    const renderModal = () => {
        if (receiptData && receiptData.length) {
            return receiptData.map(receipt => {
                return (
                    <Modal key={receipt.id} show={receipt.id === id} onHide={() => {
                        setId("");
                        setReceiptNum("");
                        setReceiptDate("");
                        setAmount("");
                        setSensor("");
                        setStrip("");
                        setLiteStrip("");
                        setNumber("");
                        setPlace("");
                        setState("");
                    }} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>

                        <Modal.Header closeButton>
                            <Modal.Title>Action ID: {receipt.id}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="text-center">

                            <div>
                                <div>
                                    <img src={receipt.receiptImage} alt="uploaded receipt" className="img-fluid" />
                                </div>

                                <div className="my-2 row px-5">
                                    <label htmlFor="receiptnumber" className="col-sm-4 col-form-label">Receipt Number: </label>
                                    <div className="col-sm-8">
                                        <input
                                            name="receiptnumber"
                                            type="text"
                                            onChange={e => setReceiptNum(e.target.value)}
                                            value={receiptNum}
                                            className="form-control"
                                            required
                                        ></input>
                                    </div>
                                </div>

                                <div className="mb-2 row px-5">
                                    <label htmlFor="amount" className="col-sm-4 col-form-label">Amount: </label>
                                    <div className="col-sm-8">
                                        <input
                                            name="amount"
                                            type="number"
                                            onChange={e => setAmount(e.target.value)}
                                            value={amount}
                                            className="form-control"
                                        ></input>
                                    </div>
                                </div>

                                <div className="mb-2 row px-5">
                                    <label htmlFor="receiptDate" className="col-sm-4 col-form-label">Receipt Date: </label>
                                    <div className="col-sm-8">
                                        <input
                                            name="date"
                                            type="date"
                                            onChange={e => setReceiptDate(e.target.value)}
                                            value={receiptDate}
                                            className="form-control"
                                            required
                                        ></input>
                                    </div>
                                </div>

                                <div className="mb-2 row px-5">
                                    <label htmlFor="sensor" className="col-sm-4 col-form-label">Sensor: </label>
                                    <div className="col-sm-8">
                                        <input
                                            name="sensor"
                                            type="number"
                                            onChange={e => setSensor(e.target.value)}
                                            value={sensor}
                                            className="form-control"
                                            min="0"
                                        ></input>
                                    </div>
                                </div>

                                <div className="mb-2 row px-5">
                                    <label htmlFor="strip" className="col-sm-4 col-form-label">Strip: </label>
                                    <div className="col-sm-8">
                                        <input
                                            name="strip"
                                            type="number"
                                            onChange={e => setStrip(e.target.value)}
                                            value={strip}
                                            className="form-control"
                                            min="0"
                                        ></input>
                                    </div>
                                </div>

                                <div className="mb-2 row px-5">
                                    <label htmlFor="litestrip" className="col-sm-4 col-form-label">Lite Strip: </label>
                                    <div className="col-sm-8">
                                        <input
                                            name="litestrip"
                                            type="number"
                                            onChange={e => setLiteStrip(e.target.value)}
                                            value={liteStrip}
                                            className="form-control"
                                            min="0"
                                        ></input>
                                    </div>
                                </div>

                                <div className="mb-2 row px-5">
                                    <label htmlFor="place" className="col-sm-4 col-form-label">Place: </label>
                                    <div className="col-sm-8">
                                        <input
                                            name="place"
                                            type="text"
                                            onChange={e => setPlace(e.target.value)}
                                            value={place}
                                            className="form-control"
                                        ></input>
                                    </div>
                                </div>

                                <div className="mb-2 row px-5">
                                    <label htmlFor="state" className="col-sm-4 col-form-label">State: </label>
                                    <div className="col-sm-8">
                                        <input
                                            name="state"
                                            type="text"
                                            onChange={e => setState(e.target.value)}
                                            value={state}
                                            className="form-control"
                                        ></input>
                                    </div>
                                </div>

                                <div className="pt-2 text-center">
                                    {selected === "pending" ? <button className="btn btn-primary me-2" onClick={handleApprove}>Approve</button> : null}
                                    {/* {selected === "pending" ? <button className="btn btn-success" onClick={handleIssue}>Issue</button> : null} */}
                                    {selected === "pending" /* || selected === "issue"*/ ? <button className="btn btn-danger" onClick={handleReject}>Reject</button> : null}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal >
                );
            });
        }
    };

    return (
        <div className="admin-page">
            <Sidebar />

            <div className="admin-body">
                <AdminTopbar />

                <div className="admin-content">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Receipt Validation</h5>
                        </div>

                        <div className="card-body">
                            <div className="d-flex">
                                <div className="me-2">
                                    <input type="date" id="startDate" name="startDate" onChange={e => setStartDate(e.target.value)} value={startDate} />
                                </div>
                                <div className="me-2">
                                    <input type="date" id="endDate" name="endDate" onChange={e => setEndDate(e.target.value)} value={endDate} />
                                </div>
                                <div className="me-2">
                                    <button onClick={handleFilter} className="btn btn-dark">Filter</button>
                                </div>
                                <div className="me-2">
                                    <button onClick={handleReset} className="btn btn-dark">Reset</button>
                                </div>
                            </div>

                            <div className="d-flex mt-5">
                                <button className="btn btn-dark" onClick={() => handleSelect("pending")} style={renderColor("pending")}>Pending</button>
                                {/* <button className="btn btn-dark" onClick={() => handleSelect("issue")} style={renderColor("issue")}>Issue</button> */}
                                <button className="btn btn-dark" onClick={() => handleSelect("approved")} style={renderColor("approved")}>Approved</button>
                                <button className="btn btn-dark" onClick={() => handleSelect("rejected")} style={renderColor("rejected")}>Rejected</button>
                            </div>

                            <div className="mt-5 text-center">
                                {loading ? <Spinner style={{ display: "block", margin: "auto", marginTop: "5px" }} animation="border" variant="primary" /> : <MDBDataTable data={data} noBottomColumns striped hover responsive bordered small />}
                            </div>
                        </div>


                        <div className="card-footer">
                            <div>
                                <CSVLink className="btn btn-dark" data={csvData.data} headers={csvData.headers} filename={`receiptReport_${csvDate}.csv`} target="_blank">
                                    Download {selected} report
                                </CSVLink>
                            </div>
                        </div>
                        {renderModal()}
                    </div>
                </div>
            </div>

        </div>
    )

}

const mapStateToProps = state => {
    return {
        token: state.admin.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        adminLogout: () => dispatch(adminLogout()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptValidation);