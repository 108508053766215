import React, { useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkToken } from "../../redux/actions/userActions";

// components
import Topbar from "./components/topbar";

// images
import tng from "../../assets/image/tng.png";

const TngRedemption = ({ user, token, checkToken }) => {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!token) {
            navigate('/login');
        } else if (!location.state || !location.state.tngCode) {
            navigate('/homepage');
        } else if (!user) {
            checkToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const doneRedeem = () => {
        navigate('/homepage');
    }

    return (
        <div>
            <Topbar />
            <div className="container">
                <p className="page-title">REDEMPTION</p>
                <img src={tng} alt="tng" className="welcome-product-img" />
                <p className="tng-reward-title pt-3 pb-4">RM {location.state.tngValue ? location.state.tngValue : "80"} TOUCH N' GO E-WALLET RELOAD PIN</p>

                <div className="tng-title-background mx-3">
                    <p className="ps-3 py-2"><b>VOUCHER NUMBER</b></p>
                </div>

                <p className="tng-code pt-3">{location.state.tngCode}</p>

                <div className="px-5 pt-5">
                    <button type="button" className="btn btn-primary form-control submit-btn" onClick={() => doneRedeem()}>DONE</button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        token: state.user.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkToken: () => dispatch(checkToken()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TngRedemption);