// images
import freeStyleLogo from "../../../assets/image/freestyle_logo.png";
import product from "../../../assets/image/welcome_product.png";

const WelcomeLogo = () => {
    return (
        <div>
            <img src={freeStyleLogo} alt="freeStyleLogo" className="welcome-logo mt-5" />
            <img src={product} alt="product" className="welcome-product-img" style={{ maxWidth: "276px", maxHeight: "276px" }} />
        </div>
    )
}

export default WelcomeLogo;