import { ADMIN_LOGIN, ADMIN_LOGOUT } from "../actions/type";

// admin login
export const adminLogin = data => {
    return {
        type: ADMIN_LOGIN,
        payload: data
    };
};

export const adminLogout = () => {
	return {
		type: ADMIN_LOGOUT
	};
};