import { IS_LOADING, IS_LOADED, SHOW_ERROR, CLEAR_ERROR, USER_LOGIN, VERIFY_USER, REMOVE_TOKEN, UPDATE_ADDRESS, UPDATE_SENSORSTAMP, UPDATE_STRIPSTAMP, UPDATE_LITESTRIPSTAMP, UPDATE_REWARD, UPDATE_VOUCHER, SAVE_CHANNEL } from "../actions/type";
import axios from 'axios';

// login
export const loginUser = data => dispatch => {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: IS_LOADING });
    axios.post('/api/auth/checkUser', data)
        .then(res => {
            dispatch({ type: USER_LOGIN, payload: res.data });
            dispatch({ type: IS_LOADED });
        })
        .catch(err => {
            dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
            dispatch({ type: IS_LOADED });
        });
};

// verify OTP
export const verification = data => dispatch => {
    dispatch({ type: CLEAR_ERROR });
    dispatch({ type: IS_LOADING });
    axios.post('/api/auth/verifyOtp', data)
        .then(res => {
            dispatch({ type: VERIFY_USER, payload: res.data.data });
            dispatch({ type: IS_LOADED });
        })
        .catch(err => {
            dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
            dispatch({ type: IS_LOADED });
        });
};

// check token for session
export const checkToken = () => (dispatch, getState) => {
    if (getState().user.token) {
        axios.post('/api/auth/checkToken', { token: getState().user.token })
            .then(res => dispatch({ type: VERIFY_USER, payload: res.data.data }))
            .catch(err => {
                console.log(err);
                dispatch({ type: REMOVE_TOKEN });
            });
    }
};

// update user address
export const updateAddress = data => {
    return {
        type: UPDATE_ADDRESS,
        payload: data
    };
};

// update sensor stamp
export const updateSensorStamp = data => {
    return {
        type: UPDATE_SENSORSTAMP,
        payload: data
    };
};

// update strip stamp
export const updateStripStamp = data => {
    return {
        type: UPDATE_STRIPSTAMP,
        payload: data
    };
};

// update litestrip stamp
export const updateLiteStripStamp = data => {
    return {
        type: UPDATE_LITESTRIPSTAMP,
        payload: data
    };
};

// update user reward
export const updateReward = data => {
    return {
        type: UPDATE_REWARD,
        payload: data
    };
};

// update voucher
export const updateVoucher = data => {
    return {
        type: UPDATE_VOUCHER,
        payload: data
    };
};

// save channel
export const saveChannel = data => {
    return {
        type: SAVE_CHANNEL,
        payload: data
    };
};