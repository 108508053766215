// images
import howItWorks1 from "../../../assets/image/howItWorks1.png";
import howItWorks2 from "../../../assets/image/howItWorks2.png";
import howItWorks3 from "../../../assets/image/howItWorks3.png";

const HowItWorks = () => {

    return (
        <div>
            <p className="ps-4"><b>HOW IT WORKS</b></p>

            <div className="container d-flex">
                <div>
                    <img src={howItWorks1} alt="howItWorks1" className="how-it-works-img" />
                    <p className="how-it-works-title">Purchase FreeStyle Libre Products</p>
                </div>
                <div>
                    <img src={howItWorks2} alt="howItWorks2" className="how-it-works-img" />
                    <p className="how-it-works-title">Upload receipt</p>
                </div>
                <div>
                    <img src={howItWorks3} alt="howItWorks3" className="how-it-works-img" />
                    <p className="how-it-works-title">Win Rewards after 4 purchases</p>
                </div>
            </div>
        </div>
    )
}

export default HowItWorks;