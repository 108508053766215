export const IS_LOADING = "IS_LOADING";
export const IS_LOADED = "IS_LOADED";
export const SHOW_ERROR = "SHOW_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const USER_LOGIN = "USER_LOGIN";
export const VERIFY_USER = "VERIFY_USER";
export const REMOVE_TOKEN = "REMOVE_TOKEN";
export const SAVE_CHANNEL = "SAVE_CHANNEL";

export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const UPDATE_SENSORSTAMP = "UPDATE_SENSORSTAMP";
export const UPDATE_STRIPSTAMP = "UPDATE_STRIPSTAMP";
export const UPDATE_LITESTRIPSTAMP = "UPDATE_LITESTRIPSTAMP";
export const UPDATE_REWARD = "UPDATE_REWARD";
export const UPDATE_VOUCHER = "UPDATE_VOUCHER";

export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";