import { useNavigate } from 'react-router-dom';

// images
import backIcon from "../../../assets/image/backIcon.png";
import topbarLogo from "../../../assets/image/freestyle_logo.png";
import abbottLogo from "../../../assets/image/abbott_logo.png";

const Topbar = () => {

    const navigate = useNavigate();

    return (
        <div className="topbar pt-3 ps-3">
            <img src={backIcon} alt="backIcon" onClick={() => navigate(-1)} />
            <img src={topbarLogo} alt="topbarLogo" className="topbar-logo ms-4" />
            <img src={abbottLogo} alt="abbottLogo" className="abbott-topbar-logo me-4" />
        </div>
    )
}

export default Topbar;