// components
import Topbar from "./components/topbar";

const Terms = () => {

    return (
        <div>
            <Topbar />

            <div className="container">
                <p>ABBOTT LABORATORIES (M) SDN BHD</p>
                <h5 className="pt-2">FREESTYLE PRIVILEGE PROGRAMME TERMS &amp; CONDITIONS</h5>
                <h3>General</h3>
                <ol style={{ textAlign: "justify" }}>
                    <li>FreeStyle Libre Privilege is a loyalty programme organized by Abbott Laboratories (M) Sdn Bhd [Company No. 198701004889 (163560-X)]. Registered members will enjoy special privileges and free products based on the total accumulated amount of participating products purchased.</li>
                    <li>Please read these Terms &amp; Conditions carefully. By registering, members are deemed to have read, understood, accepted and agreed to be bound by each term and condition. The Terms and Condition stated here constitute a legal agreement between you and Abbott Laboratories (M) Sdn Bhd.</li>
                    <li>Abbott Laboratories (M) Sdn Bhd Libre reserves the right to amend, modify, update, change or terminate the Loyalty Programme or any portion of the Terms and Conditions from time to time without any prior notification. Any amendments will be effective immediately.</li>
                    <li>Without limitation, the Organizer shall have the right to: (a) use excerpts, clips or still images from the photograph(s) for the Organizer’s general business purposes and for promotion; and (b) maintain copies of the photograph(s) to be made available for on-demand access by the Organizer; and (c) use all or part of the photograph(s) in connection with the distribution, promotion and sale of merchandise.</li>
                    <li>Submissions that portray or otherwise include inappropriate and/or offensive content, including provocative nudity, violence, human rights and/or environmental violation, and/or any other content deemed to be contrary to the law, religious, cultural and moral traditions are strictly prohibited and will be disqualified. A participant who submits any such entries may be permanently banned, subject to the Organizer’s discretion, from participating in any future contests.</li>
                    <li>Abbott Laboratories (M) Sdn Bhd shall not be responsible for any losses, damages, injuries, costs or expenses incurred, suffered by or arising from the participation of members in the FreeStyle Libre Privilege Campaign.</li>
                    <li>The contest is from 1<sup>st</sup> January 2022- 31<sup>th</sup> December 2022. Entries received after 31<sup>th</sup> December 2022 or are damaged, corrupted, incomplete or illegible will not be accepted.</li>
                </ol>
                <h3>Membership</h3>
                <ol style={{ textAlign: "justify" }}>
                    <li>To be a member, you may register for free via scanning the campaign QR code.</li>
                    <li>To register, you are required to provide your full name as per IC, an active mobile number that is valid in Malaysia and email (optional). Each mobile number can be registered for one membership only.</li>
                    <li>Members must register their personal details or business information and keep Abbott Laboratories (M) Sdn Bhd informed of any changes. Abbott Laboratories (M) Sdn Bhd cannot be held responsible for any loss of rewards incurred as a result of out of date details.</li>
                    <li>To activate your membership account, a One-Time-Password (OTP) will be sent to your registered mobile number via SMS. You will be required to verify your mobile number by entering the OTP.</li>
                    <li>FreeStyle Libre products cannot be collected without a successful registration and activation of your membership.</li>
                    <li>Membership is not transferable, not assignable and is for the exclusive use of the respective members only.</li>
                    <li>Abbott Laboratories (M) Sdn Bhd reserves the right to verify the eligibility of any individual who signed-up and may refuse the membership application of any individual that provides inaccurate, incomplete details or it determines to be tampering with the membership sign-up process including but not limited to providing inaccurate, incomplete or false details.</li>
                </ol>
                <h3>FreeStyle Libre Products</h3>
                <p>To redeem your rewards, you must upload a receipt for every purchase of Freestyle Libre product that you receive from an offline store and wait for 3 to 5 days to get each receipt verified.</p>
                <ol style={{ textAlign: "justify" }}>
                    <li>1 unit of FreeStyle Libre Sensor to GET 1 STAMP and 4 STAMPS to get 1 unit of FreeStyle Libre sensor FREE</li>
                    <li>1 unit of FreeStyle Optium Strips to GET 1 STAMP and 4 STAMPS to get 1 unit of FreeStyle Libre Optium Strips FREE</li>
                    <li>1 unit of FreeStyle Lite Strips to GET 1 STAMP and 4 STAMPS to get 1 unit of FreeStyle Libre Lite Strips FREE</li>
                    <li>Steps to redeem your rewards
                        <ul>
                            <li>Step 1: Scan QR Code on POSM/ Enter the link https://freestyleprivilege.com</li>
                            <li>Step 2: Register personal details</li>
                            <li>Step 3: Upload receipt for verification</li>
                            <li>Step 4: Collect stamps to redeem</li>
                            <li>Step 5: Redeem rewards with stamps</li>
                        </ul>
                        <p className="mt-2">Additional information <b>(Ended on 30<sup>th</sup> September 2022)</b></p>
                        <ul>
                            <li>RM80 Touch 'n Go voucher is only valid with purchase of 2 or more sensors in a single receipt.</li>
                            <li>You are only able to redeem the rewards types that is same as the product types that you purchased in the store</li>
                            <li>Only eligible for receipt until 30<sup>th</sup> September 2022</li>
                            <li>Final submission date to get RM80 Touch 'n Go voucher: <b>15<sup>th</sup> November 2022</b></li>
                        </ul>
                    </li>
                    <li>Every transaction receipt needs to be uploaded at the user microsite for verification purposes. Each receipt must clearly show details (receipt date, receipt number, store name, product name, product price) as required. Abbott Laboratories (M) Sdn Bhd reserves the right to refuse awarding any FreeStyle Libre products if any transaction receipts uploaded is not clear or suspect to fraudulent cases.</li>
                    <li>Upon verification, the total amount purchased will be reflected in the member’s microsite.</li>
                    <li>Abbott Laboratories (M) Sdn Bhd reserves the right to refuse awarding any FreeStyle Libre products if Abbott Laboratories (M) Sdn Bhd identifies that the FreeStyle Libre products collected were in error.</li>
                </ol>
                <h3>Data Protection and Privacy</h3>
                <ol style={{ textAlign: "justify" }}>
                    <li>By registering for this Loyalty Campaign, members agree to provide their personal information and Abbott Laboratories (M) Sdn Bhd reserves the right to store, process, publish and use the names and / or photos of the participants and winners in addition to their participation for publicity, advertising and / or trade purposes without further compensation or notice and participants shall not have the right to claim against the Abbott Laboratories (M) Sdn Bhd for the aforementioned usage.</li>
                </ol>
            </div>
        </div>
    )
}

export default Terms;