import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkToken, updateAddress } from "../../redux/actions/userActions";
import axios from 'axios';
import { Spinner } from 'react-bootstrap';

// components
import Topbar from "./components/topbar";

const AddAddress = ({ user, token, checkToken, phoneNumber, updateAddress }) => {

    const [line1, setLine1] = useState("");
    const [line2, setLine2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [postCode, setPostCode] = useState("");
    const [country, setCountry] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate('/login');
        } else if (!user) {
            checkToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = e => {
        e.preventDefault();

        setError("");
        setLoading(true);

        axios.post('/api/reward/addAddress', { number: phoneNumber, line1, line2, city, state, postCode, country })
            .then(res => {
                updateAddress(res.data.address);
                navigate(-1);
                setLoading(false);
            })
            .catch(err => {
                setError(err.response.data.error);
                setLoading(false);
            });
    }

    return (
        <div>
            <Topbar />

            <div className="container">
                <p className="page-title">DELIVERY DETAILS</p>

                <p>Fill in your delivery address</p>

                <form onSubmit={handleSubmit}>
                    <div>
                        <label><b>ADDRESS LINE 1</b></label>
                        <input type="text" className="form-control input-fields" id="line1" name="line1" placeholder="House No." value={line1} onChange={e => setLine1(e.target.value)} required />
                    </div>
                    <div className="pt-3">
                        <label><b>ADDRESS LINE 2</b></label>
                        <input type="text" className="form-control input-fields" id="line2" name="line2" placeholder="Street Name" value={line2} onChange={e => setLine2(e.target.value)} required />
                    </div>
                    <div className="row pt-3">
                        <div className="col">
                            <label><b>CITY</b></label>
                            <input type="text" className="form-control input-fields" id="city" name="city" placeholder="City" value={city} onChange={e => setCity(e.target.value)} required />
                        </div>
                        <div className="col">
                            <label><b>STATE</b></label>
                            <input type="text" className="form-control input-fields" id="state" name="state" placeholder="State" value={state} onChange={e => setState(e.target.value)} required />
                        </div>
                    </div>
                    <div className="row pt-3">
                        <div className="col">
                            <label><b>POSTCODE</b></label>
                            <input type="text" className="form-control input-fields" id="postCode" name="postCode" placeholder="Postcode" value={postCode} onChange={e => setPostCode(e.target.value)} required />
                        </div>
                        <div className="col">
                            <label><b>COUNTRY</b></label>
                            <input type="text" className="form-control input-fields" id="country" name="country" placeholder="Country" value={country} onChange={e => setCountry(e.target.value)} required />
                        </div>
                    </div>

                    {
                        error ? (
                            <div>
                                <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                            </div>
                        ) : null
                    }

                    {
                        loading ? (
                            <div className="pt-5">
                                <Spinner style={{ display: "block", margin: "auto", marginTop: "5px" }} animation="border" variant="primary" />
                            </div>
                        ) : (
                            <div className="px-5 pt-5">
                                <input type="submit" value="SUBMIT" className="btn btn-primary form-control submit-btn" />
                            </div>
                        )
                    }

                </form>

            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        token: state.user.token,
        phoneNumber: state.user.phoneNumber
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkToken: () => dispatch(checkToken()),
        updateAddress: data => dispatch(updateAddress(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAddress);