import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import { adminLogin } from "../../redux/actions/adminActions";
import { connect } from 'react-redux';

const AdminLogin = ({ adminLogin }) => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const navigate = useNavigate();

    const handleSubmit = e => {
        e.preventDefault();

        setError("");
        setLoading(true);

        axios.post('/api/admin/login', { username, password })
            .then(response => {
                adminLogin(response.data);
                navigate('/admin/receiptValidation');
                setLoading(false);
            })
            .catch(err => {
                setError(err.response.data.error);
                setLoading(false);
            });
    }

    return (

        <div id="admin-login">
            <div id="admin-login-container">
                <div id="admin-image-container">
                    <div id="image-container">
                        <img src="https://res.cloudinary.com/laundrocdn/image/upload/v1607313380/abbott/abbottnav_gkdiv1.png" alt="Abbott" className="img-fluid" />
                        <h1>FREESTYLE LIBRE</h1>
                    </div>
                </div>

                <div id="admin-login-form">
                    <div>
                        <h4>Admin Login</h4>
                        <p><small>Please enter your username and password to login.</small></p>
                    </div>

                    <form onSubmit={handleSubmit}>
                        {error ? (
                            <div className="alert alert-warning" role="alert">
                                <strong>{error}</strong>
                            </div>
                        ) : null}
                        <div>
                            <input type="text" id="username" name="username" placeholder="Username" className="form-control" onChange={e => setUsername(e.target.value)} value={username} required />
                            <input type="password" id="adminpassword" name="password" placeholder="Password" className="form-control mt-2" onChange={e => setPassword(e.target.value)} value={password} required />
                            
                            <div>
                                {loading ? <Spinner style={{ display: "block", margin: "auto", marginTop: "5px" }} animation="border" variant="primary" /> : <input type="submit" className="btn btn-primary form-control mt-3 submit-btn" value="Sign In" />}
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )

}

const mapDispatchToProps = dispatch => {
    return {
        adminLogin: data => dispatch(adminLogin(data)),
    };
};

export default connect(null, mapDispatchToProps)(AdminLogin);