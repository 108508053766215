import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarContent } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { Link } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import abottImage from '../../../assets/image/abbott_logo.png'

const Sidebar = () => {

    return (
        <div id="navbar-container">
            <ProSidebar breakPoint="sm" id="react-pro-sidebar">
                <SidebarHeader style={{ textAlign: "center", padding: "0px", margin: "0px" }}>
                    <Link to="/admin/dashboard" style={{ padding: 0, margin: 0, color: "white" }}>
                        <img src={abottImage} alt="small abbott" className="img-fluid" style={{ padding: "0px", margin: "0px", height: "53px" }} />
                    </Link>
                </SidebarHeader>

                <SidebarContent>
                    <Menu popperArrow={true}>

                        <MenuItem icon={<i className="fa fa-users"></i>}>
                            Receipt Validation<Link to="/admin/receiptValidation" />
                        </MenuItem>

                    </Menu>
                </SidebarContent>
            </ProSidebar>
        </div>
    )
}

export default Sidebar;