import { ADMIN_LOGIN, ADMIN_LOGOUT } from "../actions/type";

const initState = {
	token: localStorage.getItem("freestyle-admin-token"),
	role: localStorage.getItem("freestyle-admin-role")
};

const adminReducer = (state = initState, action) => {
	switch (action.type) {
		case ADMIN_LOGIN:
			localStorage.setItem("freestyle-admin-role", action.payload.role)
			localStorage.setItem("freestyle-admin-token", action.payload.token)
			return {
				...state,
				token: action.payload.token,
				role: action.payload.role
			};
		case ADMIN_LOGOUT:
			localStorage.removeItem("freestyle-admin-token");
			localStorage.removeItem("freestyle-admin-role");
			return {
				...state,
				token: null,
				role: null
			};
		default:
			return state;
	}
};

export default adminReducer;
