import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { ProgressBar, Spinner, Carousel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { checkToken } from "../../redux/actions/userActions";

// components
import Topbar from "./components/topbar";

// images
import presentGrey from "../../assets/image/presentGrey.png";
import presentWhite from "../../assets/image/presentWhite.png";
import sensor from "../../assets/image/sensor.png";
import strips from "../../assets/image/optiumNeo.png";
import liteStrips from "../../assets/image/liteStrips.jpg";
import previousIcon from "../../assets/image/previousIcon.png";
import nextIcon from "../../assets/image/nextIcon.png";

const Rewards = ({ user, token, checkToken, stamp, transaction, reward, voucher }) => {
    let sensorBatch = reward.filter(r => r.mytype === "sensor").length;
    let stripBatch = reward.filter(r => r.mytype === "strip").length;
    let litestripBatch = reward.filter(r => r.mytype === "litestrip").length;

    const [select, setSelect] = useState("Rewards");
    const [sensorIndex, setSensorIndex] = useState(sensorBatch + parseInt(stamp.sensor / 4) || 0);
    const [stripIndex, setStripIndex] = useState(stripBatch + parseInt(stamp.strip / 4) || 0);
    const [litestripIndex, setLitestripIndex] = useState(litestripBatch + parseInt(stamp.liteStrip / 4) || 0);

    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate('/login');
        } else if (!user) {
            checkToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSensorSelect = (selectedIndex, e) => {
        setSensorIndex(selectedIndex);
    };

    const handleStripSelect = (selectedIndex, e) => {
        setStripIndex(selectedIndex);
    };

    const handleLitestripSelect = (selectedIndex, e) => {
        setLitestripIndex(selectedIndex);
    };

    const claimSensorRewards = () => {
        if (stamp) {
            if (stamp.sensor >= 4) {
                navigate('/claimRewards/sensor');
            }
        }
    }

    const claimStripsRewards = () => {
        if (stamp) {
            if (stamp.strip >= 4) {
                navigate('/claimRewards/strip');
            }
        }
    }

    const claimLiteStripsRewards = () => {
        if (stamp) {
            if (stamp.liteStrip >= 4) {
                navigate('/claimRewards/litestrip');
            }
        }
    }

    let redeemSensorRewards = [];

    if (stamp) {
        for (let i = 0; i < parseInt(stamp.sensor / 4); i++) {
            redeemSensorRewards.push(
                <Carousel.Item key={sensorBatch + 1 + i}>
                    <div className="rewards-page-card" style={{ backgroundColor: "#FFD100" }}>
                        <div className="container">
                            <div className="row justify-content-between pt-3">
                                <div className="col d-flex">
                                    <div className="receipt-image-card"><img src={sensor} alt="sensor" style={{ width: "50px" }} /></div>
                                    <p className="rewards-title ps-1">Freestyle Libre Sensors puchased</p>
                                </div>
                                <p className="rewards-number col-2">4</p>
                            </div>
                            <div className="row justify-content-between">
                                <div className="col pt-2 d-flex">
                                    <div className="col"><ProgressBar now={100} className="rewards-progess-bar" /></div>
                                    <div className="col ps-1"><ProgressBar now={100} className="rewards-progess-bar" /></div>
                                    <div className="col ps-1"><ProgressBar now={100} className="rewards-progess-bar" /></div>
                                    <div className="col ps-1"><ProgressBar now={100} className="rewards-progess-bar" /></div>
                                </div>
                                <div className="col-2"><img src={presentWhite} alt="present" /></div>
                            </div>
                            {
                                voucher ? (voucher.filter(v => v.batch === (sensorBatch + 1 + i)).map(v => {
                                    if (v.redeemed === false) {
                                        return (
                                            <button key={v._id} type="button" style={{ fontSize: "11px", width: "140px" }} className="btn btn-primary form-control submit-btn" onClick={() => navigate('/claimRewards/tng')}>Redeem TnG Voucher</button>
                                        )
                                    } else {
                                        return (
                                            <button key={v._id} type="button" style={{ fontSize: "10px", width: "140px" }} className="btn btn-primary form-control submit-btn" onClick={() => { navigate('/claimRewards/tngRedeemed', { state: { tngCode: v.code, tngValue: v.value || 80 } }) }}>Redeemed TnG Voucher</button>
                                        )
                                    }
                                })) : null
                            }
                            <button type="button" style={{ fontSize: "11px", width: "110px" }} className="btn btn-primary form-control submit-btn" onClick={() => claimSensorRewards()}>Redeem Now</button>
                            <p className="rewards-small-title pb-1"> &#187; View Participating Channels</p>
                        </div>
                    </div>
                </Carousel.Item>
            )
        }
    }

    let redeemStripRewards = [];

    if (stamp) {
        for (let i = 0; i < parseInt(stamp.strip / 4); i++) {
            redeemStripRewards.push(
                <Carousel.Item key={i}>
                    <div className="rewards-page-card" style={{ backgroundColor: "#FFD100" }}>
                        <div className="container">
                            <div className="row justify-content-between pt-3">
                                <div className="col d-flex">
                                    <div className="receipt-image-card" style={{ width: "50px" }}><img src={strips} alt="strips" style={{ width: "30px", display: "block", margin: "auto" }} /></div>
                                    <p className="rewards-title ps-1">Freestyle Optium Neo Strips 50s puchased</p>
                                </div>
                                <p className="rewards-number col-2">4</p>
                            </div>
                            <div className="row justify-content-between">
                                <div className="col pt-2 d-flex">
                                    <div className="col"><ProgressBar now={100} className="rewards-progess-bar" /></div>
                                    <div className="col ps-1"><ProgressBar now={100} className="rewards-progess-bar" /></div>
                                    <div className="col ps-1"><ProgressBar now={100} className="rewards-progess-bar" /></div>
                                    <div className="col ps-1"><ProgressBar now={100} className="rewards-progess-bar" /></div>
                                </div>
                                <div className="col-2"><img src={presentWhite} alt="present" /></div>
                            </div>
                            <button type="button" style={{ fontSize: "11px", width: "110px" }} className="btn btn-primary form-control submit-btn" onClick={() => claimStripsRewards()}>Redeem Now</button>
                            <p className="rewards-small-title pb-1"> &#187; View Participating Channels</p>
                        </div>
                    </div>
                </Carousel.Item>
            )
        }
    }

    let redeemLiteStripRewards = [];

    if (stamp) {
        for (let i = 0; i < parseInt(stamp.liteStrip / 4); i++) {
            redeemLiteStripRewards.push(
                <Carousel.Item key={i}>
                    <div className="rewards-page-card" style={{ backgroundColor: "#FFD100" }}>
                        <div className="container">
                            <div className="row justify-content-between pt-3">
                                <div className="col d-flex">
                                    <div className="receipt-image-card"><img src={liteStrips} alt="liteStrips" style={{ width: "50px" }} /></div>
                                    <p className="rewards-title ps-1">Freestyle Lite Strips 50s puchased</p>
                                </div>
                                <p className="rewards-number col-2">4</p>
                            </div>
                            <div className="row justify-content-between">
                                <div className="col pt-2 d-flex">
                                    <div className="col"><ProgressBar now={100} className="rewards-progess-bar" /></div>
                                    <div className="col ps-1"><ProgressBar now={100} className="rewards-progess-bar" /></div>
                                    <div className="col ps-1"><ProgressBar now={100} className="rewards-progess-bar" /></div>
                                    <div className="col ps-1"><ProgressBar now={100} className="rewards-progess-bar" /></div>
                                </div>
                                <div className="col-2"><img src={presentWhite} alt="present" /></div>
                            </div>
                            <button type="button" style={{ fontSize: "11px", width: "110px" }} className="btn btn-primary form-control submit-btn" onClick={() => claimLiteStripsRewards()}>Redeem Now</button>
                            <p className="rewards-small-title pb-1"> &#187; View Participating Channels</p>
                        </div>
                    </div>
                </Carousel.Item>
            )
        }
    }

    const renderSensorRewardText = () => {
        if (sensorIndex === sensorBatch + parseInt(stamp.sensor / 4)) {
            if (parseInt(stamp.sensor / 4) > 0) {
                return (
                    <p className="text-center">Click on left arrow to redeem your reward</p>
                )
            }
        }
    }

    const renderStripRewardText = () => {
        if (stripIndex === stripBatch + parseInt(stamp.strip / 4)) {
            if (parseInt(stamp.strip / 4) > 0) {
                return (
                    <p className="text-center">Click on left arrow to redeem your reward</p>
                )
            }
        }
    }

    const renderLitestripRewardText = () => {
        if (litestripIndex === litestripBatch + parseInt(stamp.liteStrip / 4)) {
            if (parseInt(stamp.liteStrip / 4) > 0) {
                return (
                    <p className="text-center">Click on left arrow to redeem your reward</p>
                )
            }
        }
    }

    return (
        <div>
            <Topbar />

            <div className="container">
                <p className="page-title">REWARDS</p>

                <div className="d-flex">
                    <div className="container" onClick={e => setSelect("Rewards")}>
                        <p className={select === "Rewards" ? "selected" : "non-selected"}>Rewards</p>
                    </div>
                    <div className="container" onClick={e => setSelect("Purchases")}>
                        <p className={select === "Purchases" ? "selected" : "non-selected"}>Purchases</p>
                    </div>
                </div>

                <div className="rewards-container">
                    {
                        select === "Rewards" ? (
                            stamp ? (
                                <div>

                                    <Carousel indicators={false} activeIndex={sensorIndex} onSelect={handleSensorSelect} variant="dark" interval={null} className="pt-3" nextIcon={<div className="carousel-icon"><img src={nextIcon} alt="nextIcon" /></div>} prevIcon={<div className="carousel-icon"><img src={previousIcon} alt="previousIcon" /></div>}>

                                        {
                                            reward ? (
                                                reward.filter(r => r.mytype === "sensor")
                                                    .map(r => {
                                                        return (
                                                            <Carousel.Item key={r.batch}>
                                                                <div className="rewards-page-card" style={{ backgroundColor: "#FFD100" }}>
                                                                    <div className="container">
                                                                        <div className="row justify-content-between pt-3">
                                                                            <div className="col d-flex">
                                                                                <div className="receipt-image-card"><img src={sensor} alt="sensor" style={{ width: "50px" }} /></div>
                                                                                <p className="rewards-title ps-1">Freestyle Libre Sensors puchased</p>
                                                                            </div>
                                                                            <p className="rewards-number col-2">4</p>
                                                                        </div>
                                                                        <div className="row justify-content-between">
                                                                            <div className="col pt-2 d-flex">
                                                                                <div className="col"><ProgressBar now={100} className="rewards-progess-bar" /></div>
                                                                                <div className="col ps-1"><ProgressBar now={100} className="rewards-progess-bar" /></div>
                                                                                <div className="col ps-1"><ProgressBar now={100} className="rewards-progess-bar" /></div>
                                                                                <div className="col ps-1"><ProgressBar now={100} className="rewards-progess-bar" /></div>
                                                                            </div>
                                                                            <div className="col-2"><img src={presentWhite} alt="present" /></div>
                                                                        </div>
                                                                        {
                                                                            voucher ? (voucher.filter(v => v.batch === (r.batch)).map(v => {
                                                                                if (v.redeemed === false) {
                                                                                    return (
                                                                                        <button key={v._id} type="button" style={{ fontSize: "11px", width: "140px" }} className="btn btn-primary form-control submit-btn" onClick={() => navigate('/claimRewards/tng')}>Redeem TnG Voucher</button>
                                                                                    )
                                                                                } else {
                                                                                    return (
                                                                                        <button key={v._id} type="button" style={{ fontSize: "10px", width: "140px" }} className="btn btn-primary form-control submit-btn" onClick={() => { navigate('/claimRewards/tngRedeemed', { state: { tngCode: v.code, tngValue: v.value || 80 } }) }}>Redeemed TnG Voucher</button>
                                                                                    )
                                                                                }
                                                                            })) : null
                                                                        }
                                                                        <p className="rewards-subtitle pt-2"><b>Redeemed</b></p>
                                                                        <p className="rewards-small-title pb-1"> &#187; View Participating Channels</p>
                                                                    </div>
                                                                </div>
                                                            </Carousel.Item>
                                                        )
                                                    })
                                            ) : null
                                        }

                                        {
                                            redeemSensorRewards
                                        }

                                        <Carousel.Item>
                                            <div className="rewards-page-card">
                                                <div className="container">
                                                    <div className="row justify-content-between pt-3">
                                                        <div className="col d-flex">
                                                            <div className="receipt-image-card"><img src={sensor} alt="sensor" style={{ width: "50px" }} /></div>
                                                            <p className="rewards-title ps-1">Freestyle Libre Sensors puchased</p>
                                                        </div>
                                                        <p className="rewards-number col-2">4</p>
                                                    </div>
                                                    <div className="row justify-content-between">
                                                        <div className="col pt-2 d-flex">
                                                            <div className="col"><ProgressBar now={stamp.sensor % 4 >= 1 ? 100 : 0} className="rewards-progess-bar" /></div>
                                                            <div className="col ps-1"><ProgressBar now={stamp.sensor % 4 >= 2 ? 100 : 0} className="rewards-progess-bar" /></div>
                                                            <div className="col ps-1"><ProgressBar now={stamp.sensor % 4 >= 3 ? 100 : 0} className="rewards-progess-bar" /></div>
                                                            <div className="col ps-1"><ProgressBar now={stamp.sensor % 4 >= 4 ? 100 : 0} className="rewards-progess-bar" /></div>
                                                        </div>
                                                        <div className="col-2"><img src={presentGrey} alt="present" /></div>
                                                    </div>
                                                    {
                                                        voucher ? (voucher.filter(v => v.batch === (sensorBatch + 1 + parseInt(stamp.sensor / 4))).map(v => {
                                                            if (v.redeemed === false) {
                                                                return (
                                                                    <button key={v._id} type="button" style={{ fontSize: "11px", width: "140px" }} className="btn btn-primary form-control submit-btn" onClick={() => navigate('/claimRewards/tng')}>Redeem TnG Voucher</button>
                                                                )
                                                            } else {
                                                                return (
                                                                    <button key={v._id} type="button" style={{ fontSize: "10px", width: "140px" }} className="btn btn-primary form-control submit-btn" onClick={() => { navigate('/claimRewards/tngRedeemed', { state: { tngCode: v.code, tngValue: v.value || 80 } }) }}>Redeemed TnG Voucher</button>
                                                                )
                                                            }
                                                        })) : null
                                                    }
                                                    <p className="rewards-subtitle pt-2"><b>{stamp.sensor % 4 === 0 ? 4 : stamp.sensor % 4 === 1 ? 3 : stamp.sensor % 4 === 2 ? 2 : stamp.sensor % 4 === 3 ? 1 : stamp.sensor % 4 === 4 ? 0 : 4} more</b> to redeem the 5th FREE!</p>
                                                    <p className="rewards-small-title pb-1"> &#187; View Participating Channels</p>
                                                </div>
                                            </div>
                                        </Carousel.Item>
                                    </Carousel>
                                    {
                                        renderSensorRewardText()
                                    }

                                    <Carousel indicators={false} activeIndex={stripIndex} onSelect={handleStripSelect} variant="dark" interval={null} className="pt-3" nextIcon={<div className="carousel-icon"><img src={nextIcon} alt="nextIcon" /></div>} prevIcon={<div className="carousel-icon"><img src={previousIcon} alt="previousIcon" /></div>}>

                                        {
                                            reward ? (
                                                reward.filter(r => r.mytype === "strip")
                                                    .map(r => {
                                                        return (
                                                            <Carousel.Item key={r.batch}>
                                                                <div className="rewards-page-card" style={{ backgroundColor: "#FFD100" }}>
                                                                    <div className="container">
                                                                        <div className="row justify-content-between pt-3">
                                                                            <div className="col d-flex">
                                                                                <div className="receipt-image-card" style={{ width: "50px" }}><img src={strips} alt="strips" style={{ width: "30px", display: "block", margin: "auto" }} /></div>
                                                                                <p className="rewards-title ps-1">Freestyle Optium Neo Strips 50s puchased</p>
                                                                            </div>
                                                                            <p className="rewards-number col-2">4</p>
                                                                        </div>
                                                                        <div className="row justify-content-between">
                                                                            <div className="col pt-2 d-flex">
                                                                                <div className="col"><ProgressBar now={100} className="rewards-progess-bar" /></div>
                                                                                <div className="col ps-1"><ProgressBar now={100} className="rewards-progess-bar" /></div>
                                                                                <div className="col ps-1"><ProgressBar now={100} className="rewards-progess-bar" /></div>
                                                                                <div className="col ps-1"><ProgressBar now={100} className="rewards-progess-bar" /></div>
                                                                            </div>
                                                                            <div className="col-2"><img src={presentWhite} alt="present" /></div>
                                                                        </div>
                                                                        <p className="rewards-subtitle pt-2"><b>Redeemed</b></p>
                                                                        <p className="rewards-small-title pb-1"> &#187; View Participating Channels</p>
                                                                    </div>
                                                                </div>
                                                            </Carousel.Item>
                                                        )
                                                    })
                                            ) : null
                                        }

                                        {
                                            redeemStripRewards
                                        }

                                        <Carousel.Item>
                                            <div className="rewards-page-card">
                                                <div className="container">
                                                    <div className="row justify-content-between pt-3">
                                                        <div className="col d-flex">
                                                            <div className="receipt-image-card" style={{ width: "50px" }}><img src={strips} alt="strips" style={{ width: "30px", display: "block", margin: "auto" }} /></div>
                                                            <p className="rewards-title ps-1">Freestyle Optium Neo Strips 50s puchased</p>
                                                        </div>
                                                        <p className="rewards-number col-2">4</p>
                                                    </div>
                                                    <div className="row justify-content-between">
                                                        <div className="col pt-2 d-flex">
                                                            <div className="col"><ProgressBar now={stamp.strip % 4 >= 1 ? 100 : 0} className="rewards-progess-bar" /></div>
                                                            <div className="col ps-1"><ProgressBar now={stamp.strip % 4 >= 2 ? 100 : 0} className="rewards-progess-bar" /></div>
                                                            <div className="col ps-1"><ProgressBar now={stamp.strip % 4 >= 3 ? 100 : 0} className="rewards-progess-bar" /></div>
                                                            <div className="col ps-1"><ProgressBar now={stamp.strip % 4 >= 4 ? 100 : 0} className="rewards-progess-bar" /></div>
                                                        </div>
                                                        <div className="col-2"><img src={presentGrey} alt="present" /></div>
                                                    </div>
                                                    <p className="rewards-subtitle pt-2"><b>{stamp.strip % 4 === 0 ? 4 : stamp.strip % 4 === 1 ? 3 : stamp.strip % 4 === 2 ? 2 : stamp.strip % 4 === 3 ? 1 : stamp.strip % 4 === 4 ? 0 : 4} more</b> to redeem the 5th FREE!</p>
                                                    <p className="rewards-small-title pb-1"> &#187; View Participating Channels</p>
                                                </div>
                                            </div>
                                        </Carousel.Item>
                                    </Carousel>

                                    {
                                        renderStripRewardText()
                                    }

                                    <Carousel indicators={false} activeIndex={litestripIndex} onSelect={handleLitestripSelect} variant="dark" interval={null} className="pt-3" nextIcon={<div className="carousel-icon"><img src={nextIcon} alt="nextIcon" /></div>} prevIcon={<div className="carousel-icon"><img src={previousIcon} alt="previousIcon" /></div>}>

                                        {
                                            reward ? (
                                                reward.filter(r => r.mytype === "litestrip")
                                                    .map(r => {
                                                        return (
                                                            <Carousel.Item key={r.batch}>
                                                                <div className="rewards-page-card" style={{ backgroundColor: "#FFD100" }}>
                                                                    <div className="container">
                                                                        <div className="row justify-content-between pt-3">
                                                                            <div className="col d-flex">
                                                                                <div className="receipt-image-card"><img src={liteStrips} alt="liteStrips" style={{ width: "50px" }} /></div>
                                                                                <p className="rewards-title ps-1">Freestyle Lite Strips 50s puchased</p>
                                                                            </div>
                                                                            <p className="rewards-number col-2">4</p>
                                                                        </div>
                                                                        <div className="row justify-content-between">
                                                                            <div className="col pt-2 d-flex">
                                                                                <div className="col"><ProgressBar now={100} className="rewards-progess-bar" /></div>
                                                                                <div className="col ps-1"><ProgressBar now={100} className="rewards-progess-bar" /></div>
                                                                                <div className="col ps-1"><ProgressBar now={100} className="rewards-progess-bar" /></div>
                                                                                <div className="col ps-1"><ProgressBar now={100} className="rewards-progess-bar" /></div>
                                                                            </div>
                                                                            <div className="col-2"><img src={presentWhite} alt="present" /></div>
                                                                        </div>
                                                                        <p className="rewards-subtitle pt-2"><b>Redeemed</b></p>
                                                                        <p className="rewards-small-title pb-1"> &#187; View Participating Channels</p>
                                                                    </div>
                                                                </div>
                                                            </Carousel.Item>
                                                        )
                                                    })
                                            ) : null
                                        }

                                        {
                                            redeemLiteStripRewards
                                        }

                                        <Carousel.Item>
                                            <div className="rewards-page-card">
                                                <div className="container">
                                                    <div className="row justify-content-between pt-3">
                                                        <div className="col d-flex">
                                                            <div className="receipt-image-card"><img src={liteStrips} alt="liteStrips" style={{ width: "50px" }} /></div>
                                                            <p className="rewards-title ps-1">Freestyle Lite Strips 50s puchased</p>
                                                        </div>
                                                        <p className="rewards-number col-2">4</p>
                                                    </div>
                                                    <div className="row justify-content-between">
                                                        <div className="col pt-2 d-flex">
                                                            <div className="col"><ProgressBar now={stamp.liteStrip % 4 >= 1 ? 100 : 0} className="rewards-progess-bar" /></div>
                                                            <div className="col ps-1"><ProgressBar now={stamp.liteStrip % 4 >= 2 ? 100 : 0} className="rewards-progess-bar" /></div>
                                                            <div className="col ps-1"><ProgressBar now={stamp.liteStrip % 4 >= 3 ? 100 : 0} className="rewards-progess-bar" /></div>
                                                            <div className="col ps-1"><ProgressBar now={stamp.liteStrip % 4 >= 4 ? 100 : 0} className="rewards-progess-bar" /></div>
                                                        </div>
                                                        <div className="col-2"><img src={presentGrey} alt="present" /></div>
                                                    </div>
                                                    <p className="rewards-subtitle pt-2"><b>{stamp.liteStrip % 4 === 0 ? 4 : stamp.liteStrip % 4 === 1 ? 3 : stamp.liteStrip % 4 === 2 ? 2 : stamp.liteStrip % 4 === 3 ? 1 : stamp.liteStrip % 4 === 4 ? 0 : 4} more</b> to redeem the 5th FREE!</p>
                                                    <p className="rewards-small-title pb-1"> &#187; View Participating Channels</p>
                                                </div>
                                            </div>
                                        </Carousel.Item>
                                    </Carousel>

                                    {
                                        renderLitestripRewardText()
                                    }

                                </div>
                            ) : <Spinner style={{ display: "block", margin: "auto", marginTop: "5px" }} animation="border" variant="primary" />
                        ) : (
                            <div className="px-3">

                                {
                                    transaction ? (transaction.map(t => {
                                        return (
                                            <div key={t._id}>
                                                {
                                                    t.product.sensor ? (
                                                        <div className="faq-card mt-3">
                                                            <div className="px-3 py-3 d-flex">
                                                                <div>
                                                                    <img src={sensor} alt="purchaseHistory" style={{ width: "50px" }} />
                                                                </div>
                                                                <div className="ps-3">
                                                                    <p className="rewards-title"><b>{t.product.sensor} FREESTYLE LIBRE SENSOR</b></p>
                                                                    <p className="rewards-subtitle pt-2">Purchased on {new Date(t.receiptDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                }
                                                {
                                                    t.product.strip ? (
                                                        <div className="faq-card mt-3">
                                                            <div className="px-3 py-3 d-flex">
                                                                <div>
                                                                    <img src={strips} alt="purchaseHistory" style={{ width: "50px" }} />
                                                                </div>
                                                                <div className="ps-3">
                                                                    <p className="rewards-title"><b>{t.product.strip} FREESTYLE OPTIUM NEO STRIPS 50s</b></p>
                                                                    <p className="rewards-subtitle pt-2">Purchased on {new Date(t.receiptDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                }
                                                {
                                                    t.product.liteStrip ? (
                                                        <div className="faq-card mt-3">
                                                            <div className="px-3 py-3 d-flex">
                                                                <div>
                                                                    <img src={liteStrips} alt="purchaseHistory" style={{ width: "50px" }} />
                                                                </div>
                                                                <div className="ps-3">
                                                                    <p className="rewards-title"><b>{t.product.liteStrip} FREESTYLE LITE STRIPS 50s</b></p>
                                                                    <p className="rewards-subtitle pt-2">Purchased on {new Date(t.receiptDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                }
                                            </div>
                                        )
                                    })) : <Spinner style={{ display: "block", margin: "auto", marginTop: "5px" }} animation="border" variant="primary" />
                                }

                            </div>
                        )
                    }
                </div>

                <div className="px-5 py-3">
                    <p className="auth-subtitle">Upload receipt to win more rewards from Abbott</p>
                    <button type="button" className="btn btn-primary form-control mt-2 submit-btn" onClick={() => navigate('/uploadReceipt')}>UPLOAD RECEIPT</button>
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        token: state.user.token,
        stamp: state.user.stamp,
        transaction: state.user.transaction,
        reward: state.user.reward,
        voucher: state.user.voucher
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkToken: () => dispatch(checkToken()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rewards);