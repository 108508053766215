import { IS_LOADING, IS_LOADED, SHOW_ERROR, CLEAR_ERROR, USER_LOGIN, VERIFY_USER, REMOVE_TOKEN, UPDATE_ADDRESS, UPDATE_SENSORSTAMP, UPDATE_STRIPSTAMP, UPDATE_LITESTRIPSTAMP, UPDATE_REWARD, UPDATE_VOUCHER, SAVE_CHANNEL } from "../actions/type";

const initState = {
    isLoading: false,
    error: null,
    token: localStorage.getItem("freestyle-user"),
    user: null,
    phoneNumber: localStorage.getItem("phoneNumber"),
    identity: null,
    stamp: [],
    address: [],
    transaction: [],
    reward: [],
    voucher: [],
    channel: localStorage.getItem("channel")
};

const userReducer = (state = initState, action) => {
    switch (action.type) {
        case IS_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case IS_LOADED:
            return {
                ...state,
                isLoading: false
            };
        case SHOW_ERROR:
            return {
                ...state,
                error: action.payload
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: null
            };
        case USER_LOGIN:
            localStorage.setItem("phoneNumber", action.payload.number);
            return {
                ...state,
                identity: action.payload.identity,
                phoneNumber: action.payload.number
            };
        case VERIFY_USER:
            localStorage.setItem("freestyle-user", action.payload.token);
            return {
                ...state,
                token: action.payload.token,
                user: action.payload.usr,
                stamp: action.payload.collectedStamps,
                address: action.payload.addresses,
                transaction: action.payload.transactions,
                reward: action.payload.rewards,
                voucher: action.payload.vouchers
            };
        case REMOVE_TOKEN:
            localStorage.removeItem("freestyle-user");
            localStorage.removeItem("phoneNumber");
            localStorage.removeItem("channel");
            return {
                ...state,
                isLoading: false,
                error: null,
                token: null,
                user: null,
                phoneNumber: null,
                identity: null,
                stamp: [],
                address: [],
                transaction: [],
                reward: [],
                voucher: [],
                channel: null
            };
        case UPDATE_ADDRESS:
            return {
                ...state,
                address: [...state.address, action.payload]
            };
        case UPDATE_SENSORSTAMP:
            return {
                ...state,
                stamp: {
                    ...state.stamp,
                    sensor: action.payload
                }
            };
        case UPDATE_STRIPSTAMP:
            return {
                ...state,
                stamp: {
                    ...state.stamp,
                    strip: action.payload
                }
            };
        case UPDATE_LITESTRIPSTAMP:
            return {
                ...state,
                stamp: {
                    ...state.stamp,
                    liteStrip: action.payload
                }
            };
        case UPDATE_REWARD:
            return {
                ...state,
                reward: [...state.reward, action.payload]
            };
        case UPDATE_VOUCHER:
            return {
                ...state,
                voucher: action.payload
            };
        case SAVE_CHANNEL:
            localStorage.setItem("channel", action.payload);
            return {
                ...state,
                channel: action.payload
            };
        default:
            return state;
    }
};

export default userReducer;
