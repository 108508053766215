import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkToken } from "../../redux/actions/userActions";

// components
import Topbar from "./components/topbar";

const HelpReceiveReward = ({ user, token, checkToken }) => {

    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate('/login');
        } else if (!user) {
            checkToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Topbar />

            <div className="container">
                <p className="page-title">HELP</p>
                <div className="px-3 pt-1">
                    <h5><b>How long does it take to receive my rewards?</b></h5>
                    <p className="pt-3">10-12 working days. However, there might be a possible delay should the receipt image not clearly uploaded, or not valid contact number, or incomplete mailing address. Alternatively, for more information, you can also contact us via {<a href="http://wa.link/4z1755">WhatsApp</a>}, available from Monday to Friday, 9AM to 5PM.</p>

                    <h5 className="pt-5"><b>RELATED QUESTIONS</b></h5>
                    <div className="faq-card mt-3" onClick={() => navigate('/help/loyaltyProgram')}>
                        <p className="rewards-subtitle px-2 pt-3">What is the loyalty programme about?</p>
                    </div>
                    <div className="faq-card mt-2" onClick={() => navigate('/help/redeemReward')}>
                        <p className="rewards-subtitle px-2 pt-3">How do I redeem my rewards?</p>
                    </div>
                    <div className="faq-card mt-2" onClick={() => navigate('/help/validateReceipt')}>
                        <p className="rewards-subtitle px-2 pt-3">How long does it take to validate my receipt? What is required during the validation process?</p>
                    </div>
                    <div className="faq-card mt-2" onClick={() => navigate('/help/getReward')}>
                        <p className="rewards-subtitle px-2 pt-3">What are some of the rewards I can get from participating the loyalty programme?</p>
                    </div>
                    <div className="faq-card mt-2" onClick={() => navigate('/help/amountPurchase')}>
                        <p className="rewards-subtitle px-2 pt-3">Is there any minimum amount of purchase to validate the receipt?</p>
                    </div>
                    <div className="faq-card my-2" onClick={() => navigate('/help/contact')}>
                        <p className="rewards-subtitle px-2 pt-3">Who should I contact should I have any queries with regards to Abbott FreeStyle products?</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        token: state.user.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkToken: () => dispatch(checkToken()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpReceiveReward);