import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import queryString from "query-string";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

import { connect } from 'react-redux';
import { loginUser, saveChannel } from "../../redux/actions/userActions";

import retailer from "../../assets/image/retailer.png";

// components
import WelcomeLogo from "./components/welcomeLogo";

const mySwal = withReactContent(Swal);

const Login = ({ isLoading, error, login, identity, saveChannel }) => {

    const [phoneNumber, setPhoneNumber] = useState("");

    const location = useLocation();
    const { utm_source } = queryString.parse(location.search);

    useEffect(() => {
        if (utm_source) {
            saveChannel(utm_source);
        }
        mySwal.fire({
            title: "Reminder",
            text: "Submit your receipt before 1st Jan 2023 to redeem your rewards!",
            icon: "warning",
            confirmButtonColor: "#05539B"
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = e => {
        e.preventDefault();

        login({ number: phoneNumber });

    }

    if (identity === "NEW") {
        return <Navigate to="/register" />
    } else if (identity === "EXIST") {
        return <Navigate to="/verification" />
    } else {
        return (
            <div className="welcome-container">
                <WelcomeLogo />
                <div style={{ position: "absolute", top: "7px", right: "7px", display: "flex" }}>
                    <div><img src={retailer} alt="retailer" /></div>
                    <a className="ps-2" href="https://ambassador.freestyleprivilege.com/">I'm Ambassador</a>
                </div>
                <div className="px-5">
                    <p className="auth-title">LOG IN</p>
                    <p className="auth-subtitle">Please enter your mobile number to verify your account</p>
                    <form onSubmit={handleSubmit}>
                        <input type="number" className="form-control" id="phoneNumber" name="phoneNumber" placeholder="Phone Number" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} required />

                        {
                            error ? (
                                <div>
                                    <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                                </div>
                            ) : null
                        }

                        {
                            isLoading ? <Spinner style={{ display: "block", margin: "auto", marginTop: "5px" }} animation="border" variant="primary" /> : <input type="submit" value="LOG IN" className="btn btn-primary form-control mt-3 submit-btn" />
                        }

                    </form>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        isLoading: state.user.isLoading,
        error: state.user.error,
        identity: state.user.identity
    };
};

const mapDispatchToProps = dispatch => {
    return {
        login: data => dispatch(loginUser(data)),
        saveChannel: data => dispatch(saveChannel(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);