import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkToken } from "../../redux/actions/userActions";

// components
import Topbar from "./components/topbar";

// video
import product1 from "../../assets/video/product1.mp4";
import product2 from "../../assets/video/product2.mp4";
import product3 from "../../assets/video/product3.mp4";
import product4 from "../../assets/video/product4.mp4";

const OurProducts = ({ user, token, checkToken }) => {

    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate('/login');
        } else if (!user) {
            checkToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Topbar />

            <div className="container">
                <p className="page-title">PRODUCT INFORMATION</p>

                <div className="faq-card mt-3">
                    <div className="px-3 py-3">
                        <p><b>Introducing the Freestyle Libre system</b></p>
                        <video src={product1} width="300px" controls style={{ display: "block", margin: "auto" }}>
                        </video>
                    </div>
                </div>

                <div className="faq-card mt-3">
                    <div className="px-3 py-3">
                        <p><b>How to use the sensor and reader</b></p>
                        <video src={product2} width="300px" controls style={{ display: "block", margin: "auto" }}>
                        </video>
                    </div>
                </div>

                <div className="faq-card my-3">
                    <div className="px-3 py-3">
                        <p><b>Review about Freestyle Libre product from Kevin</b></p>
                        <video src={product3} width="300px" controls style={{ display: "block", margin: "auto" }}>
                        </video>
                    </div>
                </div>

                <div className="faq-card my-3">
                    <div className="px-3 py-3">
                        <p><b>How to apply the FreeStyle Libre Sensor</b></p>
                        <video src={product4} width="300px" controls style={{ display: "block", margin: "auto" }}>
                        </video>
                    </div>
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        token: state.user.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkToken: () => dispatch(checkToken()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OurProducts);